<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.alarm.icon" title="一键报警" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="img.pole.online" />
    <span class="device-item-header-status">在线</span>
  </div>
  <img class="wifi-flag" :src="img.pole.alarm.center" @click="showAlarmModals" />
  <table border="0">
    <tr>
      <td class="table-title">报警总数</td>
      <td class="table-value">{{speakTotal}}</td>
    </tr>
    <tr>
      <td class="table-title">今日报警</td>
      <td class="table-value">{{speakToday}}</td>
    </tr>
    <tr>
      <td class="table-title">最后报警时间</td>
      <td class="table-value">{{speakLast}}</td>
    </tr>
  </table>
  <ModalList v-model="showAlarmModal" />
</div>
</template>
<script>
import ModalList from '@/views/alarm/ModalList'
export default {
  name: 'SpeakStationViewer',
  components:{
    ModalList
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      speakLast: '',
      speaks: [],
      speakTotal: 0,
      speakToday: 0,
      showAlarmModal: false,
    }
  },
  computed: {},
  watch: {},
  mounted: function(){
    this.getWillSieHistory();
  },
  methods: {
    showAlarmModals: function(){
      this.showAlarmModal = true;
    },
    getWillSieHistory: async function(){
      // console.log('get speak history', this.item)
      if(!this.item.content.guid){
        this.$set(this, 'speaks', []);
        this.speakTotal = 0;
        this.speakLast = '-'
        return;
      }
      let res = await this.$axios.get(`//${this.domains.trans}/pole/WillSieSpeak?devId=${this.item.content.guid}&index=0&size=10000`);
      if(res.code != 0){
        return;
      }
      //当RecordFile字段不为空时，表示有录音文件，可使用以下路径访问录音文件：http://120.24.217.89:8089/group1/talk_record/100000-100003_2022-06-01-14-18-01.wav

      if(!res.data.length)res.data = [];
      this.$set(this, 'speaks', res.data);
      this.speakTotal = res.data.length;
      let today = parseInt(new Date(new Date().toLocaleDateString()).getTime() / 1000);
      this.speakToday = res.data.filter(item => item.TskStartTime >= today).length;
      if(res.data.length > 0){
        this.speakLast = new Date(res.data[0].TskStartTime * 1000).format("yyyy-MM-dd HH:mm:ss")
      }else{
        this.speakLast = '-'
      }
    },
  }
}
</script>
<style scoped>
.wifi-flag{
  width: 106px;
  height: 108px;
  position: absolute;
  top: 89px;
  left: 144px;
  cursor: pointer;
}
table{
  /* border: solid 1px red; */
  /* margin: auto; */
  /* width: 100%; */
  position: absolute;
  /* top: 180px; */
  left: 20px;
  right: 20px;
  bottom: 20px;
}
.table-title{
  /* background: #EEF3F8; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 150px;
  height: 32px;
  padding: 0 10px;
  text-align-last: justify;
}
.table-value{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 190px;
  height: 32px;
  padding: 0 15px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
  cursor: pointer;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
