<template>
<div class="config-station-container">
  <div class="station-search-area">
    <Button type="primary" size="default" v-for="(cmd, idx) in items" :key="idx" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">重发失败</Button>
    <!-- <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">导出结果</Button>
    <Checkbox v-model="clearOldLog">发送指令时，是否清除旧指令记录？</Checkbox> -->
    <div class="row-space"></div>
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
  </div>
  <div class="station-data-area" ref="table">
    <u-table ref="chkTable" :data="devs" use-virtual style="width: 100%;" row-key="id" border stripe size="mini" :height="tabHeight">
      <u-table-column type="selection" label="" width="50"></u-table-column>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="online" label="状态" width="50">
        <template slot-scope="params">
          <img :src="params.row.online ? img.pole.icons.pole.online : img.pole.icons.pole.offline" />
        </template>
      </u-table-column>
      <u-table-column prop="name" label="名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="stationId" label="所在灯杆" sortable width="200">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="关联设备" sortable width="200">
        <template slot-scope="params">
          {{params.row.deviceName}}
        </template>
      </u-table-column>
      <u-table-column prop="k1" label="继电器1" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.k1, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="k2" label="继电器2" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.k2, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="k3" label="继电器3" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.k3, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="s1" label="开入量1" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.s1, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="s2" label="开入量2" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.s2, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="s3" label="开入量3" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.s3, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="s4" label="开入量4" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.s4, 0) == 1 ? '通' : '断'}}
        </template>
      </u-table-column>
      <u-table-column prop="poe" label="启用POE" sortable width="100">
        <template slot-scope="params">
          {{getPoeState(params.row.lastData.poe)}}
        </template>
      </u-table-column>
      <u-table-column prop="p1s" label="1号槽状态" sortable width="100">
        <template slot-scope="params">
          {{getWorkState(params.row.lastData.p1s)}}
        </template>
      </u-table-column>
      <u-table-column prop="p2s" label="2号槽状态" sortable width="100">
        <template slot-scope="params">
          {{getWorkState(params.row.lastData.p2s)}}
        </template>
      </u-table-column>
      <u-table-column prop="p3s" label="3号槽状态" sortable width="100">
        <template slot-scope="params">
          {{getWorkState(params.row.lastData.p3s)}}
        </template>
      </u-table-column>
      <u-table-column prop="p1m" label="1号槽主电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p1m)}}
        </template>
      </u-table-column>
      <u-table-column prop="p1t" label="1号槽从电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p1t)}}
        </template>
      </u-table-column>
      <u-table-column prop="p2m" label="2号槽主电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p2m)}}
        </template>
      </u-table-column>
      <u-table-column prop="p2t" label="1号槽从电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p2t)}}
        </template>
      </u-table-column>
      <u-table-column prop="p3m" label="3号槽主电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p3m)}}
        </template>
      </u-table-column>
      <u-table-column prop="p3t" label="3号槽从电源类型" sortable width="100">
        <template slot-scope="params">
          {{getVoltageType(params.row.lastData.p3t)}}
        </template>
      </u-table-column>
      <u-table-column prop="acv" label="交流电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.acv, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="aci" label="交流电流" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.aci, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="acp" label="交流功率" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.acp, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc1m" label="1号槽主电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc1m, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc1s" label="1号槽从电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc1s, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc2m" label="2号槽主电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc2m, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc2s" label="2号槽从电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc2s, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc3m" label="3号槽主电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc3m, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="dc3s" label="3号槽从电源电压" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dc3s, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="pv" label="POE输出电压" sortable width="90">
        <template slot-scope="params">
          {{img.val(params.row.lastData.pv, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="temp" label="工作温度" sortable width="90">
        <template slot-scope="params">
          {{img.val(params.row.lastData.temp, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="install" label="最后通信时间" sortable width="170">
        <template slot-scope="params">
          {{!params.row.fireTime ? '-' : new Date(params.row.fireTime).format('yyyy-MM-dd HH:mm:ss')}}
        </template>
      </u-table-column>
      <!-- <u-table-column width="300" label="执行结果" fixed="right">
        <template slot-scope="params">
          {{getCmdStatus(params.row)}}
        </template>
      </u-table-column> -->
      <u-table-column width="300" label="执行结果" fixed="right">
        <template slot-scope="params">
          {{params.row.message}}
        </template>
      </u-table-column>
    </u-table>
  </div>
  <!-- <ModalEnvEdit v-model="showEditModal" :item="editItem" /> -->
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import ModalEnvEdit from './ModalEnvEdit'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'PoleBroadIndex',
  components:{
    // ModalEnvEdit,
  },
  data () {
    return {
      clearOldLog: false,
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        name: '',
      },
      devs: [],
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', name: '召测数据', img: this.img.light.road.single.ctrl.forceRead },
      ],
      cmdResults: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'commands', 'cmdResultRefresh']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'powerBoxPartTypes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.initDevs();
    },
    // cmdResultRefresh() {
    //   for (let c of this.commands) {
    //     let its = this.devs.filter(p => p.id == c.id);
    //     if (its.length == 0) continue;
    //     this.$set(its[0], 'status', c.status)
    //     this.$set(its[0], 'result', c.result)
    //     this.$set(its[0], 'cmd', c.code)
    //     this.$set(its[0], 'content', c.content)
    //     if(c.code == 'forceRead' && c.status == 9){
    //       this.$set(its[0].lastData, 'k1', c.content.k1);
    //       this.$set(its[0].lastData, 'k2', c.content.k2);
    //       this.$set(its[0].lastData, 'k3', c.content.k3);
    //       this.$set(its[0].lastData, 's1', c.content.s1);
    //       this.$set(its[0].lastData, 's2', c.content.s2);
    //       this.$set(its[0].lastData, 's3', c.content.s3);
    //       this.$set(its[0].lastData, 's4', c.content.s4);
    //       this.$set(its[0].lastData, 'poe', c.content.hasPoe);
    //       this.$set(its[0].lastData, 'aci', c.content.acP);
    //       this.$set(its[0].lastData, 'acp', c.content.speed);
    //       this.$set(its[0].lastData, 'acv', c.content.acV);
    //       this.$set(its[0].lastData, 'dc1m', c.content.dc1MV);
    //       this.$set(its[0].lastData, 'dc1s', c.content.dc1SV);
    //       this.$set(its[0].lastData, 'dc2m', c.content.dc2MV);
    //       this.$set(its[0].lastData, 'dc2s', c.content.dc2SV);
    //       this.$set(its[0].lastData, 'dc3m', c.content.dc3MV);
    //       this.$set(its[0].lastData, 'dc3s', c.content.dc3SV);
    //       this.$set(its[0].lastData, 'p1m', c.content.poe1MType);
    //       this.$set(its[0].lastData, 'p1s', c.content.poe1State);
    //       this.$set(its[0].lastData, 'p1t', c.content.poe1SType);
    //       this.$set(its[0].lastData, 'p2m', c.content.poe2MType);
    //       this.$set(its[0].lastData, 'p2s', c.content.poe2State);
    //       this.$set(its[0].lastData, 'p2t', c.content.poe2SType);
    //       this.$set(its[0].lastData, 'p3m', c.content.poe3MType);
    //       this.$set(its[0].lastData, 'p3s', c.content.poe3State);
    //       this.$set(its[0].lastData, 'p3t', c.content.poe3SType);
    //       this.$set(its[0].lastData, 'temp', c.content.temp);
    //       this.$set(its[0].lastData, 'poeV', c.content.poeV);
    //       this.$set(its[0], 'fireTime', c.content.time);
    //       this.$set(its[0], 'online', true)
    //     }
    //   }
    // },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.initDevs();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      this.cmdResults[params.cmdId] = params;
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devs.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
    },
    getVoltageType: function(state){
      if(state == 1)return '5V';
      if(state == 2)return '12V';
      if(state == 3)return '24V';
      if(state == 4)return '48V';
      if(state == 5)return 'POE';
      if(state == 6)return '未初始化';
      return '空或坏';
    },
    getPoeState: function(state){
      if(state == 1)return '2号槽供电';
      if(state == 2)return '3号槽供电';
      return '未启用';
    },
    getWorkState: function(state){
      if(state == 1)return '主电源工作';
      if(state == 2)return '从电源工作';
      return '独立工作';
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9:
          // console.log('getCmdStatus', cmd)
          // console.log('cmd status', cmd)
          switch (cmd.cmd) {
            case 'getclock': return `执行成功：${cmd.content.nowTime}`;
            case 'getInfo': return `硬件版本：${cmd.content.hardware}，软件版本：${cmd.content.software}，生产厂商：${cmd.content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getNetwork': return `IP:${cmd.content.host}, 端口:${cmd.content.port}, 心跳间隔:${cmd.content.heart}秒`;
            case 'forceRead': {
              let c = cmd;
              let its = this.devs.filter(p => p.id == c.id);
              if (its.length == 0) return '执行成功';
              this.$set(its[0], 'status', c.status)
              this.$set(its[0], 'result', c.result)
              this.$set(its[0], 'cmd', c.code)
              this.$set(its[0], 'content', c.content)
              this.$set(its[0].lastData, 'k1', c.content.k1);
              this.$set(its[0].lastData, 'k2', c.content.k2);
              this.$set(its[0].lastData, 'k3', c.content.k3);
              this.$set(its[0].lastData, 's1', c.content.s1);
              this.$set(its[0].lastData, 's2', c.content.s2);
              this.$set(its[0].lastData, 's3', c.content.s3);
              this.$set(its[0].lastData, 's4', c.content.s4);
              this.$set(its[0].lastData, 'poe', c.content.hasPoe);
              this.$set(its[0].lastData, 'aci', c.content.acP);
              this.$set(its[0].lastData, 'acp', c.content.speed);
              this.$set(its[0].lastData, 'acv', c.content.acV);
              this.$set(its[0].lastData, 'dc1m', c.content.dc1MV);
              this.$set(its[0].lastData, 'dc1s', c.content.dc1SV);
              this.$set(its[0].lastData, 'dc2m', c.content.dc2MV);
              this.$set(its[0].lastData, 'dc2s', c.content.dc2SV);
              this.$set(its[0].lastData, 'dc3m', c.content.dc3MV);
              this.$set(its[0].lastData, 'dc3s', c.content.dc3SV);
              this.$set(its[0].lastData, 'p1m', c.content.poe1MType);
              this.$set(its[0].lastData, 'p1s', c.content.poe1State);
              this.$set(its[0].lastData, 'p1t', c.content.poe1SType);
              this.$set(its[0].lastData, 'p2m', c.content.poe2MType);
              this.$set(its[0].lastData, 'p2s', c.content.poe2State);
              this.$set(its[0].lastData, 'p2t', c.content.poe2SType);
              this.$set(its[0].lastData, 'p3m', c.content.poe3MType);
              this.$set(its[0].lastData, 'p3s', c.content.poe3State);
              this.$set(its[0].lastData, 'p3t', c.content.poe3SType);
              this.$set(its[0].lastData, 'temp', c.content.temp);
              this.$set(its[0].lastData, 'poeV', c.content.poeV);
              this.$set(its[0], 'fireTime', c.content.time);
              this.$set(its[0], 'online', true)
              return '执行成功';
            }
            default: return `执行成功`;
          }
      }
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          type: this.deviceTypes[item.type],
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: '设备名称',
        sname: '站点名称',
        type: '设备类型',
        result: '执行结果'
      }
      exportXlsx(cmdData, tabHead, "指令执行结果");
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      for (let d of this.devs) {
        if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', this.getCmdStatus(d))
          cmds.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args });
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      setTimeout(() => {
        this.$set(this, 'cmdResults', {});
        this.$axios.post(`//${this.domains.trans}/station/command/send`, { list: cmds }).then(res => {
          if (res.code == 0) {
            // console.log('send cmd result', res.data);
            for (let item of res.data.list) {
              let ds = this.devs.filter(p => p.id == item.itemId);
              if (ds.length == 0) continue;
              this.$set(ds[0], 'cmdId', item.cmdId);
              this.$set(ds[0], 'status', item.status);
              this.$set(ds[0], 'result', item.message);
              this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
            }
          }
        });
      }, 500)
    },
    clickCmd: function (params) {
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      if (params.code == 'setclock') {
        this.cmdItem = { code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else {
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let cmds = []
        for (let chk of chks) {
          chk.result = '';
          chk.status = 0;
          chk.cmd = params.code;
          chk.name = params.name;
          chk.args = {};
          chk.message = this.getCmdStatus(chk);
          cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.deviceId, args: {} });
        }
        this.$set(this, 'cmdResults', {});
        this.$axios.post(`//${this.domains.trans}/station/command/send`, { list: cmds }).then(res => {
          if (res.code == 0) {
            // console.log('send cmd result', res.data);
            for (let item of res.data.list) {
              let ds = this.devs.filter(p => p.id == item.itemId);
              if (ds.length == 0) continue;
              this.$set(ds[0], 'cmdId', item.cmdId);
              this.$set(ds[0], 'status', item.status);
              this.$set(ds[0], 'result', item.message);
              this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
            }
          }
        });
        // this.$store.dispatch('cmd/sendCommands', cmds);
      }
    },
    initDevs: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPolePower`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.map(p => {
            p.cmdId = 0;
            p.cmd = '';
            p.args = {};
            p.cmdContent = {};
            p.time = 0;
            p.status = 0;
            p.result = '';
            p.message = '';
          });
          this.$set(this, 'devs', res.data);
          this.$refs.chkTable.toggleAllSelection();
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.row-space{
  width: 100px;
  flex: auto;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>