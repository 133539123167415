<template>
  <div class="device-item-content">
    <template v-for="(item,index) in devices">
      <div class="device-info" :key="index" ref="infoHight">
        <div class="device-info-img">
          <div class="device-info-led" :class="getImage(item)">
            <video v-if="view.type == 'Video' && view.url != ''" :src="view.url" autoplay loop muted @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
            <audio v-else-if="view.type == 'Audio' && view.url != ''" :src="view.url" autoplay loop controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
            <img v-else-if="view.type == 'Image' && view.url != ''" :src="view.url" class="media-player media-player-img" @load="imageLoaded" />
            <div v-else class="media-player">当前无播放任务</div>
          </div>
        </div>
        <div class="device-info-box">
          <div class="device-info-upper">
            <!-- <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.icons.broad.deviceName" alt="">
              </div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.name}}</div>
                <div class="device-info-title">当前播放</div>
              </div>
            </div> -->
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.asset.index.name" alt="">
              </div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.name}}</div>
                <div class="device-info-title">设备名称</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.pole" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val"> {{item.stationName}}</div>
                <div class="device-info-title">所属灯杆</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.type" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.connectTypeName}}</div>
                <div class="device-info-title">设备类型</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.date" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.buildDate}}</div>
                <div class="device-info-title">安装日期</div>
              </div>
            </div>
          </div>
          <div class="device-info-lower">
            <div class="device-info-operation">
              <span>亮度</span>
              <Slider v-model="item.lastData.bright" :min="0" @on-change="brightChange(item)" :max="100" show-input style="width: 250px;"></Slider>
            </div>
            <div class="device-info-operation">
              <span>音量</span>
              <Slider v-model="item.lastData.volume" :min="0" @on-change="volChange(item)" :max="100" show-input style="width: 250px;"></Slider>
            </div>
            <div class="device-info-operation">
              <template v-for="(el,indexs) in items">
                <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="clickCmd(el,item.id)" :key="indexs"><img :src="el.img" alt="">{{el.name}}</a>
              </template>
              <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="openTaskList()"><img :src="img.pole.icons.led.manage" alt="">播放管理</a>
            </div>
          </div>
        </div>

      </div>
    </template>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" />
    </div>
    <ModalCommandArgs v-model="showArgModal" :command="editItem" @saved="commandArgSaved" />
    <ModalTaskList v-model="showTaskModal" />
    <Modal v-model="showCaptureModal" :mask-closable="false" draggable width="1000px">
      <div slot="header">截屏预览</div>
      <div style="text-align:center;width: 100%; height: 320px; overflow-y:auto">
        <img v-show="captureUrl != ''" style="width: 192px" :src="captureUrl" />
      </div>
      <div slot="footer">
        <Button style="margin-right: 8px" @click="showCaptureModal = false">关闭</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
// import ModalCommandArgs from '../control/ModalCommandArgs'
import ModalCommandArgs from '@/components/control/pole/led/ModalCommandArgs'
import ModalTaskList from '@/views/media/task/ModalTaskList'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'deviceList',
  components: {
    recordPage,
    ModalCommandArgs,
    ModalTaskList,
    // exportXlsx,
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      items: [
        { needArgs: false, needPswd: true, code: 'openDevice', name: '开屏', img: this.img.pole.icons.led.Start },
        { needArgs: false, needPswd: true, code: 'closeDevice', name: '关屏', img: this.img.pole.icons.led.Start },
        { needArgs: true, needPswd: true, code: 'setAutoBright', name: '设置自动亮度', img: this.img.pole.icons.led.brightness },
        { needArgs: false, needPswd: true, code: 'capture', name: '屏幕截图', img: this.img.pole.icons.led.screenshot },
        { needArgs: false, needPswd: true, code: 'reboot', name: '系统重启', img: this.img.pole.icons.led.restart },
        { needArgs: true, needPswd: true, code: 'playMedia', name: '手动播放', img: this.img.pole.icons.led.Manualplay },
        { needArgs: true, needPswd: true, code: 'playTask', name: '计划播放', img: this.img.pole.icons.led.playback },
      ],
      showTaskModal: false,
      showArgModal: false,
      showCaptureModal: false,
      view: {
        type: 0,
        url: '',
      },
      plays: [],
      playIdx: 0,
      ticker: null,
      captureUrl: "",
      editItem: { isAdd: true, index: -1, data: {} },
      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      devId: -1,
      operateDevId: "",
      chartHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['cmdResultRefresh', 'ledcmd', 'ledStateRefresh', 'ledState', 'commands']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    cmdResultRefresh() {
      let cmds = this.commands.filter(p => p.id == this.operateDevId);
      if (cmds.length == 0) return;
      let cmd = cmds[0];
      if (cmd.status != 9) return;
      if (cmd.code == 'capture') {
        if (!cmd.content.screenshot) {
          this.showSpin(`未截取到图片`);
          return;
        }
        this.captureUrl = `data:image/jpeg;base64,${cmd.content.screenshot}`;
        this.showCaptureModal = true;
      } else if (cmd.code == 'capture') {
        if (!cmd.content.screenshot) {
          this.showSpin(`未截取到图片`);
          return;
        }
        this.captureUrl = `data:image/jpeg;base64,${cmd.content.screenshot}`;
        this.showCaptureModal = true;
      }
    },
  },
  mounted: function () {
    this.getCurrentMedia()
  },
  methods: {
    imageLoaded() {

    },
    audioLoaded(e) {
      e.srcElement.play();
    },
    videoLoaded(v) {
      v.srcElement.play();
    },
    changePlayMedias: function () {
      if (this.plays.length == 0) {
        this.view.type = 0;
        this.view.url = '';
      } else {
        let item = this.plays[this.playIdx];
        this.view.type = item.type;
        this.view.url = item.url;
        this.playIdx++;
        if (this.playIdx >= this.plays.length) {
          this.playIdx = 0;
        }
      }
    },
    getCurrentMedia: function () {
      if(this.devices.length <= 0)return;
      this.$axios.post(`//${this.domains.trans}/pole/led/GetCurrentMedia`, { id: this.devices[0].id }).then(res => {
        if (res.code == 0) {
          this.plays = res.data;
          this.playIdx = 0;
          this.changePlayMedias();
          if (this.ticker) {
            clearInterval(this.ticker);
          }
          this.ticker = setInterval(this.changePlayMedias, 10000);
        } else {
          this.view.type = 0;
          this.view.url = '';
        }
      });
    },
    brightChange: function (item) {
      this.$axios.post(`//${this.domains.trans}/pole/led/setBright`, { ids: [item.id], bright: item.lastData.bright }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$store.commit('cmd/ledCmdClear');
        this.tipsMessage(res.data[item.id])
      });
    },
    volChange: function (item) {
      this.$axios.post(`//${this.domains.trans}/pole/led/SetVolume`, { ids: [item.id], vol: item.lastData.volume }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$store.commit('cmd/ledCmdClear');
        this.tipsMessage(res.data[item.id])
      });
    },
    openTaskList() {
      this.showTaskModal = true;
    },
    tipsMessage(data) {
      if (!data.success) {
        this.$Message.warning(data.content);
      } else {
        this.$Message.success("执行成功");
        this.getCurrentMedia()
      }
    },
    commandArgSaved: function (params) {
      let ids = [this.operateDevId];
      if (params.cmd.code == 'playMedia') {
        this.$axios.post(`//${this.domains.trans}/pole/led/PlayMedia`, { ids: ids, mediaId: params.args.mediaId }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])

        });
      } else if (params.cmd.code == 'playTask') {
        this.$axios.post(`//${this.domains.trans}/pole/led/SetTask`, { ids: ids, taskIds: params.args.taskIds }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'openDevice') {
        this.$axios.post(`//${this.domains.trans}/pole/led/open`, { ids }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'closeDevice') {
        this.$axios.post(`//${this.domains.trans}/pole/led/close`, { ids }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'setBright') {
        this.$axios.post(`//${this.domains.trans}/pole/led/setBright`, { ids, bright: params.args.brightness }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'setVolume') {
        this.$axios.post(`//${this.domains.trans}/pole/led/setVolume`, { ids, vol: params.args.volume }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'capture') {
        this.$axios.post(`//${this.domains.trans}/pole/led/Screenshot`, { ids }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.$store.commit('cmd/ledCmdClear');
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'reboot') {
        this.$axios.post(`//${this.domains.trans}/pole/led/Reboot`, { ids }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      } else if (params.cmd.code == 'setAutoBright') {
        this.$axios.post(`//${this.domains.trans}/pole/led/SetAutoBright`, { ids, bright: params.args.brightness, sens: params.args.sens }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.tipsMessage(res.data[ids])
        });
      }
    },
    clickCmd(params, id) {
      this.operateDevId = id;
      if (params.needArgs || params.needPswd) {
        this.editItem = params;
        this.showArgModal = true;
        return;
      }
    },
    getImage: function (item) {
      if (!item.online) return 'offline';
      return 'online';
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 25px;
  margin-top: 5px;
}
.device-info-led {
  width: 72px;
  height: 126px;
  background: #cdd8e6;
  border: 3px solid #e4ecf6;
  border-radius: 4px;
}
.device-info-led video {
  width: 100%;
}
.device-info-led .offline {
  border: 3px solid #e4ecf6;
  border-radius: 4px;
}
.device-info-led .online {
  background: #007eff;
  border: 3px solid #dbf4e5;
}
.device-info-led .media-player-img {
  width: 100%;
  height: 100%;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con {
  width: 140px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.device-info-upper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.device-info-lower {
  display: flex;
  justify-content: flex-start;
}
.cameraInfo-tab-but img {
  vertical-align: middle;
  margin-right: 3px;
}
.cameraInfo-tab-but {
  height: 35px;
  padding: 2px 8px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  margin: 0 5px;
}
.device-info-operation {
  display: flex;
}
.device-info-operation span {
  width: 30px;
  flex: none;
  text-align: right;
  line-height: 35px;
  margin-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.device-info-operation /deep/ .ivu-slider-input .ivu-input-number {
  margin-top: 0;
  margin-top: 0;
  width: 45px;
  left: -40px;
}
</style>
