<template>
  <div class="device-item-content">
    <div class="device-item-top">
      <p class="device-item-topTitle">监控点名称：1号样机</p>
      <p class="device-item-topTime">更新时间：2022-08-08 08:12:00</p>
      <div class="device-item-butList">
        <a href="javascript:void(0);" class="cameraInfo-search-but">刷新</a>
        <a href="javascript:void(0);" class="cameraInfo-search-but">校准时钟</a>
        <a href="javascript:void(0);" class="cameraInfo-search-but">查询时钟</a>
      </div>
    </div>
    <div class="device-item-img">
      <deviceImg />
    </div>
    <div class="device-modular">
      <div class="device-modular-list">
        <ACSupply /><!-- AC供电 -->
      </div>
      <div class="device-modular-list">
        <MainPage /><!-- 电源主控模块 -->
      </div>
      <div class="device-modular-list">
        <OnePower /><!-- 独立电源模块 -->
      </div>
      <div class="device-modular-list">
        <OnePower /><!-- 独立电源模块 -->
      </div>
      <div class="device-modular-list">
        <ACPowerSupply /><!-- 交流供电模块 -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import deviceImg from './deviceImg'
import ACSupply from '../cell/ACSupply'
import MainPage from '../cell/main'
import OnePower from '../cell/onePower'
// import PoeSwitch from '../cell/PoeSwitch'
import ACPowerSupply from '../cell/ACPowerSupply'
export default {
  name: 'deviceDetails',
  components: {
    deviceImg,
    MainPage,
    ACPowerSupply,
    // PoeSwitch,
    OnePower,
    ACSupply,
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {

  },
  methods: {
    setHigt: function(){},
    //  case 0:
    //    name = "没有模块";
    //    break;
    //case 1:
    //    name = "Poe交换机模块";
    //    break;
    //case 2:
    //    name = "Poe电源模块";
    //    break;
    //case 3:
    //    name = "主备电源模块";
    //    break;
    //case 4:
    //    name = "独立电源模块";
    //    break;
    //case 5:
    //    name = "交流供电模块";
    //    break;

  }
}
</script>
<style scoped>
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #e6e7ef;
  border-radius: 10px;
  padding: 20px 20px;
  text-align: center;
  overflow-y: overlay;
}
.cameraInfo-search-but {
  padding: 7px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: 0 20px;
}
.device-modular {
  width: 1480px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  height: 610px;
  position: relative;
  left: 50%;
  margin-left: -742px;
  top: -10px;
}
.device-modular-list {
  width: 280px;
}
.device-item-topTitle {
  color: rgb(51, 51, 51);
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.device-item-topTime {
  color: rgb(158, 162, 166);
  font-size: 14px;
  text-align: center;
}
.device-item-butList {
  margin-top: 10px;
}
</style>
