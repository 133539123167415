<template>
<div class="asset-container">
  <div class="asset-level1">
    <Level1Content v-for="(item) in totals" :key="item.idx" :item="item" />
  </div>
  <div class="asset-level2">
    <div class="level2-total">
      <Level2Content />
    </div>
    <div class="level2-list">
      <LogsContent />
    </div>
  </div>
  <PoleList />
  <!-- <Level3Content class="asset-level3"/> -->
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Level1Content from './level1/Index'
import Level2Content from './level2/Index'
import LogsContent from './logs/Index'
import PoleList from './list/Index'
// import Level3Content from './level3/Index'
export default {
  name: 'Home',
  components: {
    Level1Content,
    Level2Content,
    LogsContent,
    PoleList,
  },
  data() {
    return {
      totals: [
        {icon: this.img.pole.asset.level1.total, title: '站点总数', count: 25, unit: '', color: ''},
        {icon: this.img.pole.asset.level1.create, title: '设备总数', count: 15, unit: '', color: '#4792EC'},
        {icon: this.img.pole.asset.level1.running, title: '在线数', count: 95, unit: '', color: '#35D288'},
        {icon: this.img.pole.asset.level1.alarm, title: '故障数', count: 15, unit: '', color: '#EA6352'},
        {icon: this.img.pole.asset.level1.day, title: '当日用电量', count: 15, unit: '', color: '#8793A5'},
        {icon: this.img.pole.asset.level1.month, title: '当月用电量', count: 15, unit: '', color: '#8793A5'},
        {icon: this.img.pole.asset.level1.utotal, title: '累计用电量', count: 110, unit: '', color: '#8793A5'},
      ]
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
    selectedNode(){
      this.initData();
    },
  },
  created: function () {
  },
  mounted: function () {
    this.initData();
  },
  methods: {
    initData: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleCount`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this.totals[0], 'count', res.data.total);
        this.$set(this.totals[1], 'count', res.data.device);
        this.$set(this.totals[2], 'count', res.data.online);
        this.$set(this.totals[3], 'count', res.data.alarm);
        this.$set(this.totals[4], 'count', res.data.usageDay);
        this.$set(this.totals[5], 'count', res.data.usageMonth);
        this.$set(this.totals[6], 'count', res.data.usageTotal);
    // console.log('pole main goup index', this.totals)
      })
    },
  },
}
</script>
<style scoped>
.asset-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* background-color: white; */
  border-radius: 8px;
  overflow-y: scroll;
}
.asset-level1{
  /* border: solid 1px red; */
  height: 90px;
  flex: none;
  margin-bottom: 15px;
  display: flex;
  /* justify-content: space-between; */
}
.asset-level2{
  /* border: solid 1px red; */
  height: 305px;
  flex: none;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.level2-total{
  /* border: solid 1px red; */
  width: 637px;
  margin-right: 15px;
  flex: none;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}
.level2-list{
  /* border: solid 1px red; */
  width: 560px;
  flex: auto;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}
.asset-level3{
  /* border: solid 1px blue; */
  /* height: 600px; */
  flex: none;
  overflow: hidden;
  /* margin-bottom: 15px; */
}
</style>