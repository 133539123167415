<template>
  <div class="videoFace-control">
    <div class="videoFace-title">摄像头信息 <span v-if="devices.length!=0"><img class="device-item-header-status-icon" :src="player ? img.pole.online : img.pole.offline" />{{player? '在线' : '离线'}}</span>
      <div class="videoFace-choice">
        <span>请选择摄像头：</span>
        <Select v-model="tool" class="videoFace-type">
          <template v-for="item in tools">
            <Option :value="item.code" :key="item.code">{{item.name}}</Option>
          </template>
        </Select>
      </div>
    </div>
    <div class="videoFace-con" v-if="devices.length>0">
      <div id="vehicleId" class="videoFace-player" :key="timer"></div>
    </div>
    <div class="videoFace-con" v-if="devices.length==0">
      暂无摄像头
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EZUIKit from 'ezuikit-js';
export default {
  name: 'Home',
  components: {
  },
  props: {
    devices: {
      type: Array,
      default() { return []; }
    },
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  data() {
    return {
      timer: 0,
      player: null,
      tool: "s1",
      tools: [
        { name: "过道", code: "s1" },
        { name: "走廊", code: "s2" },
        { name: "茶水间", code: "s3" },
        { name: "大门", code: "s4" },
      ],
    }
  },
  watch: {
    devices() {
      this.timer = new Date().getTime();
      this.urlAjax();
    }
  },
  mounted: function () {
    this.urlAjax();
  },
  methods: {
    urlAjax: async function () {
      if (this.devices.length < 0) { return false }
      let ajaxData = this.devices[0];
      this.player = null;
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/Preview`, { ids: [ajaxData.id] });
      this.timer = new Date().getTime();
      if (res.code !== 0) {
        return;
      }
      setTimeout(() => {
        let id = "vehicleId";
        let v = document.getElementById(id);
        let token = res.data[ajaxData.id].token;
        let url = res.data[ajaxData.id].content;
        let rect = v.getBoundingClientRect();
        this.player = new EZUIKit.EZUIKitPlayer({
          id: id,
          accessToken: token,
          url: url,
          autoplay: true,
          width: rect.width,
          height: rect.height,
        });
        v.style.overflow = 'hidden';
      }, 100);
    },
  }
}
</script>
<style scoped>
.videoFace-control {
  height: calc(100%);
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
}
.videoFace-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.videoFace-title span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-left: 15px;
}
.videoFace-title img {
  vertical-align: middle;
  margin-right: 5px;
}
.videoFace-con {
  width: 100%;
  height: calc(100% - 35px);
}
.videoFace-player {
  width: 100%;
  height: 100%;
}
.videoFace-choice {
  float: right;
}
.videoFace-choice span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.videoFace-type {
  width: 150px;
  height: 32px;
  color: #007eff;
}
</style>
