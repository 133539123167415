<template>
  <div class="pole-container">
    <div class="pole-header">
      <template v-for="(item, idx) in menus">
        <div :class="['header-item', item.code == tab ? 'header-active':'']" v-if="item.range.indexOf(appType) != -1 && canOp(item.opCode)" :key="idx" @click="switchMenu(item.code)">{{item.name}}</div>
      </template>
    </div>
    <div class="pole-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == tab && item.com &&  canOp(item.opCode)" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AccountTangYuanIndex from './tangyuan/Index'
import AccountWillSieIndex from './willsie/Index'
import AccountYingShiIndex from './yingshiyun/Index'
import AccountZxklIndex from './zxkl/Index'
import AccountZhsrIndex from './zhuhaisanrun/Index'
import AccountYunLianIndex from './yunlianyouke/Index'
export default {
  name: 'Home',
  components: {
  },
  props: {
    args: {
      type: Object,
      default() { return { tab: 's1' } }
    },
  },
  data() {
    return {
      tab: this.args.tab,
      menus: [
        { code: 's1', name: '萤石云平台', com: AccountYingShiIndex, args: {}, range: ['pole'] ,opCode:["ayv",'aya','aye','ayd']},//opCode 判断查看功能
        { code: 's2', name: '唐沅云平台', com: AccountTangYuanIndex, args: {}, range: ['pole'] ,opCode:["atv",'ata','ate','atd']},
        { code: 's3', name: '威尔西云平台', com: AccountWillSieIndex, args: {}, range: ['pole'] ,opCode:["awv",'awa','awe','awd']},
        { code: 's4', name: '中兴克拉', com: AccountZxklIndex, args: {}, range: ['pole', 'road', 'factory', 'light'] ,opCode:["azv",'aza','aze','azd']},
        { code: 's5', name: '珠海三润', com: AccountZhsrIndex, args: {}, range: ['pole'] ,opCode:["asrv",'asra','asre','asrd']},
        { code: 's6', name: '云联友科', com: AccountYunLianIndex, args: {}, range: ['pole'] ,opCode:["aylv",'ayla','ayle','ayld']},
      ]
    }
  },
  computed: {
    ...mapState('auth', ['appType', 'user', 'ops']),
  },
  watch: {
  },
  mounted: function () {
    // console.log('account index mouted', this.appType, this.args)
  },
  destroyed: function () {
  },
  methods: {
    canOp(opCode) {
      if (this.user && this.user.isSuper) return true;
      if(opCode.length==0) return true;
      for (let index = 0; index < opCode.length; index++) {
        if (this.ops && this.ops.indexOf(opCode[index]) != -1) {
          return true;
        }
      }
      return false;
    },
    switchMenu: function (menu) {
      this.tab = menu;
    },
  }
}
</script>
<style scoped>
.pole-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  border-radius: 12px;
  /* border: solid 1px red; */
  padding: 10px;
}
.pole-header {
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.header-item {
  padding: 0 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #152942;
  cursor: pointer;
}
.header-item::after {
  content: "";
  display: block;
  width: 70%;
  height: 4px;
  margin: 0 auto;
  margin-top: 3px;
  border: solid 2px white;
}
.header-active {
  font-weight: bolder;
  position: relative;
}
.header-active::after {
  border: solid 2px #3781fe;
}
.pole-content {
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
}
</style>
