<template>
<div class="pole-container">
  <div class="pole-header">
    <div :class="['header-item', item.code == tab ? 'header-active':'']" v-for="(item, idx) in menus" :key="idx" @click="switchMenu(item.code)">{{item.name}}</div>
  </div>
  <div class="pole-content">
    <template v-for="(item, idx) in menus">
      <component v-if="item.code == tab && item.com" :key="idx" v-bind:is="item.com" :args="item.args"></component>
    </template>
  </div>
  <!-- <ManholeBaseIndex class="content-item" v-if="selectedNode.type == productCodes.station" />
  <ManholeGroupIndex v-else /> -->
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import PowerControlIndex from '@/views/pole/power/control/Index'
import PowerControlIndex from '@/components/control/pole/power/Index'
// import PowerConfigIndex from '@/views/pole/config/power/Index'
// import ConfigLockIndex from '@/views/pole/config/lock/Index'
// import ConfigVideoIndex from '@/views/pole/config/video/Index'
// import BroadControlIndex from '@/views/pole/broad/control/Index'
import BroadControlIndex from '@/components/control/pole/broad/Index'
// import SpeakConfigIndex from '@/views/pole/config/speak/Index'
// import LedControlIndex from '@/views/pole/led/control/Index'
import LedControlIndex from '@/components/control/pole/led/Index'
// import LedConfigIndex from '@/views/pole/config/led/Index'
// import EnvControlIndex from '@/views/pole/env/control/Index'
import EnvControlIndex from '@/components/control/pole/env/Index'
// import WifiConfigIndex from '@/views/pole/config/wifi/Index'
// import ChargeConfigIndex from '@/views/pole/config/charge/Index'
// import ManholeConfigIndex from '@/views/pole/config/manhole/Index'
// import GarbageConfigIndex from '@/views/pole/config/garbage/Index'
// import PhoneConfigIndex from '@/views/pole/config/phone/Index'
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      tab: 'sub1',
      menus: [
        {code: 'sub1', type: 309, name: '电源网关', com: PowerControlIndex, args: {}},
        // {code: 'sub2', type: 311, name: '智能锁', com: ConfigLockIndex, args: {}},
        // {code: 'sub3', type: 301, name: '视频监控', com: ConfigVideoIndex, args: {}},
        {code: 'sub4', type: 304, name: '广播', com: BroadControlIndex, args: {}},
        // {code: 'sub5', type: 305, name: '可视对讲', com: SpeakConfigIndex, args: {}},
        {code: 'sub6', type: 302, name: 'LED屏', com: LedControlIndex, args: {}},
        {code: 'sub7', type: 303, name: '环境监测', com: EnvControlIndex, args: {}},
        // {code: 'sub8', type: 306, name: '城市WIFI', com: WifiConfigIndex, args: {}},
        // {code: 'sub9', type: 307, name: '充电桩', com: ChargeConfigIndex, args: {}},
        // {code: 'sub10', type: 320, name: '智能井盖', com: ManholeConfigIndex, args: {}},
        // {code: 'sub11', type: 310, name: '智能垃圾桶', com: GarbageConfigIndex, args: {}},
        // {code: 'sub12', type: 312, name: '手机充电', com: PhoneConfigIndex, args: {}},
      ]
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    // selectedNode(){
    // },
  },
  mounted: function(){
    // this.$store.commit('group/setDeviceTypes', [this.productCodes.station])
  },
  destroyed: function(){
  },
  methods: {
    switchMenu: function(menu){
      this.tab = menu;
    },
  }
}
</script>
<style scoped>
.pole-container{
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  border-radius: 12px;
  /* border: solid 1px red; */
  padding: 10px;
}
.pole-header{
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.header-item{
  padding: 0 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #152942;
  cursor: pointer;
}
.header-item::after{
  content: '';
  display: block;
  width: 70%;
  height: 4px;
  margin: 0 auto;
  margin-top: 3px;
  border: solid 2px white;
}
.header-active{
  font-weight: bolder;
  position: relative;
}
.header-active::after{
  border: solid 2px #3781FE;
}
.pole-content{
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
}
.content-item{
  height: 200px;
  flex: auto;
  /* margin-right: 40px; */
  /* border: solid 1px red; */
  overflow: hidden;
}
</style>
