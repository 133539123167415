<template>
  <div class="list-container">
    <u-table ref="chkTable" :data="stations" style="width: 100%;" use-virtual row-key="id" border stripe :height="500" default-expand-all :header-row-class-name="setHeaderRowClass" :header-row-style="{textAlign: 'center'}">
      <u-table-column label="" width="120">
        <u-table-column prop="id" type="index"></u-table-column>
        <u-table-column prop="name" label="站点名称" :show-overflow-tooltip="true">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
      </u-table-column>
      <u-table-column label="站点状态">
        <u-table-column prop="name" label="在线状态" class-name="cell-text-center">
          <template slot-scope="params">
            <img :src="params.row.online ? img.light.road.tree.online : img.light.road.tree.offline" />
          </template>
        </u-table-column>
        <u-table-column prop="name" label="报警状态" class-name="cell-text-center">
          <template slot-scope="params">
            <img :src="params.row.alarm ? img.light.road.tree.online : img.light.road.tree.alarm" />
          </template>
        </u-table-column>
        <u-table-column prop="name" label="最后通信时间" width="155">
          <template slot-scope="params">
            {{params.row.refreshTime == '0001-01-01 00:00:00' ? '--' : params.row.refreshTime}}
          </template>
        </u-table-column>
        <u-table-column prop="name" label="查看详情" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" @click="viewDetail(params.row, 'power')">查看详细</a>
          </template>
        </u-table-column>
      </u-table-column>
      <u-table-column label="设备明细 （总数 / 在线 / 报警）">
        <u-table-column label="照明" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'light')">{{params.row.lightTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'light')">{{params.row.lightOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'light')">{{params.row.lightAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="视频" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'video')">{{params.row.videoTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'video')">{{params.row.videoOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'video')">{{params.row.videoAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="广播" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'broad')">{{params.row.broadTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'broad')">{{params.row.broadOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'broad')">{{params.row.broadAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="对讲" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'speak')">{{params.row.speakTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'speak')">{{params.row.speakOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'speak')">{{params.row.speakAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="LED" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'led')">{{params.row.ledTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'led')">{{params.row.ledOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'led')">{{params.row.ledAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="环测" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'env')">{{params.row.envTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'env')">{{params.row.envOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'env')">{{params.row.envAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="WIFI" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'wifi')">{{params.row.wifiTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'wifi')">{{params.row.wifiOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'wifi')">{{params.row.wifiAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="充电桩" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'charge')">{{params.row.chargeTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'charge')">{{params.row.chargeOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'charge')">{{params.row.chargeAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="井盖" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'manhole')">{{params.row.manholeTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'manhole')">{{params.row.manholeOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'manhole')">{{params.row.manholeAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="垃圾桶" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'garbage')">{{params.row.garbageTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'garbage')">{{params.row.garbageOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'garbage')">{{params.row.garbageAlarm}}</a>
          </template>
        </u-table-column>
        <u-table-column label="手机充电" class-name="cell-text-center">
          <template slot-scope="params">
            <a href="javascript:void(0);" class="color4" title="总数" @click="gotoParamStatus(params.row, 'phone')">{{params.row.phoneTotal}}</a> /
            <a href="javascript:void(0);" class="color1" title="在线" @click="gotoParamStatus(params.row, 'phone')">{{params.row.phoneOnline}}</a> /
            <a href="javascript:void(0);" class="color2" title="报警" @click="gotoParamStatus(params.row, 'phone')">{{params.row.phoneAlarm}}</a>
          </template>
        </u-table-column>
      </u-table-column>
    </u-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'StationBaseIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      stations: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    selectedNode(){
      this.initParamCount();
    },
  },
  mounted: function () {
    this.initParamCount();
  },
  destroyed: function () {
  },
  methods: {
    initParamCount: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleStatus`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'stations', res.data);
      })
    },
    setHeaderRowClass: function (params) {
      return `header-row-class-${params.rowIndex}`
    },
    viewDetail: function (params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.id, type: this.productCodes.station });
    },
    gotoParamStatus: function (params, tabName) {
      this.$store.commit('auth/setPoleMenuName', tabName);
      setTimeout(() => {
        this.$store.commit('group/selectNodeById', { nodeId: params.id, type: this.productCodes.station });
      }, 200)
    }
  }
}
</script>
<style>
.cell-text-center {
  text-align: center !important;
}
.header-row-class-0 {
  background: #4b9cff;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  height: 54px;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-0 > th {
  /* text-align: center !important; */
  background: #4b9cff !important;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-0 > th > .cell {
  text-align: center !important;
  background: #4b9cff;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-1 {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
  color: #324252;
  height: 54px;
}
.header-row-class-1 > th {
  /* text-align: center !important; */
  /* background: #4B9CFF !important; */
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-1 > th > .cell {
  text-align: center !important;
  /* background: #4B9CFF;
  border-radius: 6px 6px 0px 0px; */
}
</style>
<style scoped>
.header-back {
  background: #4b9cff;
  border-radius: 6px 6px 0px 0px;
}
.header-text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  height: 54px;
}
.list-container table {
  width: 100%;
  height: 93px;
  margin-bottom: 15px;
  padding-bottom: 50px;
}
.split-td {
  border-right: solid 1px #d0d9e9 !important;
}
.table-td {
  width: 115px;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  height: 54px;
}
.list-container table tr:last-child td {
  border-bottom: none;
}
.list-container table tr td:last-child {
  border-right: none;
}
.color1 {
  color: #2bd58c;
}
.color2 {
  color: #fec71e;
}
.color3 {
  color: #ff5d4c;
}
.color4 {
  color: #b153e7;
}
</style>