<template>
  <div>
    <template v-if="selectedNode.type!='Station'">
      <div class="asset-level1">
        <Level1Content :item="totals" />
      </div>
    </template>
    <div class="video-control-container" :class="[selectedNode.type!='Station'?'':'station']">
      <div class="video-control-slider">
        <div class="slider-title">云台控制</div>
        <div class="slider-control">
          <Panel style="margin-bottom: 10px;" @setPanel="setPanel" />
        </div>
        <div class="slider-input" style="margin-bottom: 10px;">
          云台速度
          <Slider v-model="speed" :min="0" :max="2" style="width: 100px;flex:auto;margin:0 5px;"></Slider>
          <Input type="number" v-model="speed" :min="0" :max="2" number style="width:50px;flex:none;" />
        </div>
        <!-- <div class="slider-input" style="margin-bottom: 10px;">
        <Tool @action="toolAction" />
      </div> -->
        <div class="slider-input" style="margin-bottom: 10px;">
          <Preset @action="toolAction" :videlID="(devices[videlIndex]||{}).id" />
        </div>
        <div class="slider-title-switch">
          <a href="javascript:void(0);" class="slider-title-but " :class="[sliderIndex==0?'active':'']" @click="sliderSwitch(0)">摄像头参数</a>
          <a href="javascript:void(0);" class="slider-title-but" :class="[sliderIndex==1?'active':'']" @click="sliderSwitch(1)">历史回放</a>
        </div>
        <div class="slider-list" :class="[selectedNode.type!='Station'?'':'station']">
          <parameter v-if="sliderIndex==0"/>
          <Playback v-if="sliderIndex==1" :videlID="(devices[videlIndex]||{}).id"/>
        </div>
      </div>
      <div class="video-control-content">
        <table class="video-player-list" v-if="devices.length>0">
          <tr v-for="n in playerCountY" :key="n">
            <td v-for="i in playerCountX" :key="i" :style="{width:`${100/playerCountX}%`}" @click.stop="videlSelect({n,i,playerCountX})">
              <Player class="video-player-item" :item="videoShow(n,i)" :index="{n,i,playerCountX}" :key="timer" :speed="speed" :videlIndex="videlIndex" />
            </td>
          </tr>
        </table>
        <div class="video-player-tool">
          <div class="player-tool-item" v-for="n in 4" :key="n" @click="switchPlayerCount(n)">{{n*n}}</div>
          <div class="player-tool-space"></div>
          <img :src="img.pole.homePage.leftBut" alt="" @click="previousPage" v-if="pageTurning>1">
          <img :src="img.pole.homePage.rigBut" style="margin-left:15px" alt="" @click="nextPage">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Panel from './Panel'
// import Tool from './Tool'
import Preset from './Preset'
import Playback from '../history/playback'
import Level1Content from '../level1/Index2'
import parameter from '../config/parameter'
import Player from './Player'
export default {
  name: 'Home',
  components: {
    Panel,
    // Tool,
    Preset,
    Level1Content,
    Player,
    parameter,
    Playback,

  },
  props: {
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  data() {
    return {
      speed: 1,
      playerCount: 1,
      playerCountX: 1,
      playerCountY: 1,
      timer: 0,
      direction: -1,
      videlIndex: 0,
      pageTurning: 1,//翻页
      sliderIndex: 0,
      devices:[],
      totals: [
        { icon: this.img.pole.asset.level3.total7, title: '设备总数', count: 25, unit: '', color: '' },
        { icon: this.img.pole.asset.level3.running, title: '在线', count: 95, unit: '', color: '#35D288' },
        { icon: this.img.pole.asset.level3.alarm, title: '离线', count: 15, unit: '', color: '#EA6352' },
        { icon: this.img.pole.asset.level3.create, title: '报警', count: 15, unit: '', color: '#4792EC' },
      ]
    }
  },
  watch: {
    selectedDevices() {
    },
    selectedNode() {
      this.getVideos();
    },
    speed() {
      this.direction = this.speed;
    }
  },
  mounted: function () {
    this.getVideos();
  },
  methods: {
    getVideos: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleVideo`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        res.data.map(p => {
          if(!p.content){
            p.content = {};
          }
          if(!p.content.tasks){
            p.content.tasks = [];
          }
        })
        this.$set(this, 'devices', res.data);
        this.countVideo();
        this.initData();
      })
    },
    sliderSwitch(index) {
      this.sliderIndex = index;
    },
    initData: function () {
      let total = 0, online = 0, offline = 0, alarm = 0;//, volLess = 0, move = 0, water = 0;
      for (let m of this.devices) {
        total++;
        if (m.online) {
          online++;
        } else {
          offline++;
        }
        if (m.alarm) {
          alarm++;
        }
      }
      this.$set(this.totals[0], 'count', total);
      this.$set(this.totals[1], 'count', online);
      this.$set(this.totals[2], 'count', offline);
      this.$set(this.totals[3], 'count', alarm);
    },
    countVideo() {
      if (this.devices.length == 1) {
        this.playerCountX = 1;
        this.playerCountY = 1;
      } else {
        let sqrt = Math.ceil(Math.sqrt(this.devices.length))
        this.playerCountX = sqrt > 4 ? 4 : sqrt;
        this.playerCountY = sqrt > 4 ? 4 : sqrt;
      }
      this.timer = new Date().getTime();
    },

    videlSelect(data) {
      this.videlIndex = (data.n - 1) * data.playerCountX + (data.i - 1);
    },
    previousPage() {
      this.pageTurning--;
      this.timer = new Date().getTime();
    },
    nextPage() {
      if (this.playerCountX * this.playerCountX >= this.devices.length) {
        return false;
      }
      this.pageTurning++;
      this.timer = new Date().getTime();
    },
    videoShow(n, i) {//行   列
      let len = (n - 1) * this.playerCountX + (i - 1);
      len = len + this.playerCountX * (this.pageTurning - 1);
      return this.devices[len]
    },
    setPanel(msg) {
      this.direction = msg;
    },
    switchPlayerCount: function (n) {
      this.pageTurning = 1;
      this.playerCountX = n;
      this.playerCountY = n;
      let data = { n: 1, i: 1, playerCount: n }
      this.videlSelect(data)
      this.timer = new Date().getTime();
    },
    toolAction: function (params) {
      console.log("tool action", params.open, params.tool);
    },
  }
}
</script>
<style scoped>
.video-control-container {
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  height: 690px;
  overflow-y: revert;
}
.video-control-container.station {
  height: 790px;
}
.video-control-slider {
  /* border: solid 1px red; */
  width: 330px;
  flex: none;
  padding: 5px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}
.slider-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #24334a;
  line-height: 30px;
  margin-bottom: 10px;
}
.slider-title-but {
  width: 110px;
  height: 36px;
  background: #ebf4fe;
  border-radius: 18px;
  margin-right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  text-align: center;
  line-height: 36px;
  display: inline-block;
  margin-top: 15px;
}
.slider-title-but.active {
  background: #007eff;
  color: #ffffff;
}

.slider-control {
  height: 180px;
  flex: none;
}
.slider-input {
  display: flex;
  align-items: center;
}
.video-control-content {
  border: solid 3px #acc8e8;
  border-radius: 12px;
  width: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  background-color: black;
}
.slider-list {
  height: 310px;
  margin-top: 10px;
}
.slider-list.station {
  height: 410px;
}
.video-player-tool {
  height: 42px;
  flex: none;
  background: linear-gradient(180deg, #3c3c3c, #0e0e0e);
  opacity: 0.9;
  border-radius: 0 0 12px 12px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
td {
  /* border: solid 1px red; */
}
.video-selected {
  border: solid 1px #007eff;
  border-radius: 2px;
}
.video-player-list {
  width: 100%;
  height: 400px;
  flex: auto;
  /* border: solid 1px red; */
}
.player-tool-item {
  width: 18px;
  height: 18px;
  background: linear-gradient(180deg, #ffffff, #999999);
  border-radius: 2px;
  margin-right: 10px;
  flex: none;
  cursor: pointer;
  text-align: center;
  line-height: 18px;
}
.player-tool-space {
  width: 100px;
  flex: auto;
}
.asset-level1 {
  height: 90px;
  flex: none;
  margin-bottom: 15px;
  display: flex;
}
</style>
