<template>
<div class="pole-container">
  <ManholeBaseIndex class="content-item" v-if="selectedNode.type == productCodes.station" />
  <ManholeGroupIndex v-else />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ManholeBaseIndex from './base/Index'
import ManholeGroupIndex from './group/Index'
export default {
  name: 'Home',
  components: {
    ManholeBaseIndex,
    ManholeGroupIndex,
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    // selectedNode(){
    // },
  },
  mounted: function(){
    // this.$store.commit('group/setDeviceTypes', [this.productCodes.station])
  },
  destroyed: function(){
  },
  methods: {
    switchMenu: function(menu){
      this.curMenu = menu;
    },
    returnBase: function(){
      this.curMenu = 'base';
    },
  }
}
</script>
<style scoped>
.pole-container{
  background: #E3E9EF;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
}
.content-item{
  height: 200px;
  flex: auto;
  /* margin-right: 40px; */
  /* border: solid 1px red; */
  overflow: hidden;
}
</style>
