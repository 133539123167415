<template>
  <div class="config-station-container">
    <div class="station-search-area">
      起始时间：
      <DatePicker v-model="filter.start" type="datetime" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      截止时间：
      <DatePicker v-model="filter.end" type="datetime" placeholder="请选择截止时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      <Button type="info" :loading="loading" @click="getWillSieHistory" style="margin-right: 10px;">查询</Button>
    </div>
    <div class="station-data-area" ref="table">
      <u-table :data="history" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="filter.total" :page-size="filter.pageSize" :current-page="filter.pageIndex" @handlePageSize="handlePageSize">
        <u-table-column type="index" label="序号" width="50"></u-table-column>
        <u-table-column prop="TskStartTime" label="呼叫时间" sortable width="155">
          <template slot-scope="params">
            {{params.row.startTime}}
          </template>
        </u-table-column>
        <u-table-column prop="TskGuid" label="任务ID" sortable>
          <template slot-scope="params">
            {{params.row.taskGuid}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="呼叫方" sortable>
          <template slot-scope="params">
            {{params.row.callerNickName}}
          </template>
        </u-table-column>
        <u-table-column prop="guid" label="被呼叫方" sortable>
          <template slot-scope="params">
            {{params.row.calleeNickName}}
          </template>
        </u-table-column>
        <u-table-column prop="ModificationTime" label="结束时间" sortable width="155">
          <template slot-scope="params">
            {{params.row.endTime}}
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Device',
  components: {
  },
  props: {
  },
  data() {
    return {
      previewUrl: '',
      tabHeight: 300,
      loading: false,
      filter: {
        total: 0,
        pageIndex: 1,
        pageSize: 15,
        callerId: '',
        calleeId: '',
        start: '',
        end: '',
        taskGuid: '',
      },
      history: [],
      devs: [],
      count: 0,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getWillSieHistory();
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.setTableHeight();
    }, 20);
    let now = new Date();
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    this.getWillSieHistory();
  },
  methods: {
    handlePageSize: function (params) {
      this.filter.pageSize = params.size;
      this.filter.pageIndex = params.page;
      this.getWillSieHistory();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getWillSieDevices: async function () {
      let res = await this.$axios.get(`//${this.domains.trans}/pole/WillSieDevice?index=0&size=10000`);
      if (res.code != 0) {
        return;
      }
      this.$set(this, 'devs', res.data);
    },
    getWillSieHistory: async function () {
      let filter = {
        pageIndex: this.filter.pageIndex,
        pageSize: this.filter.pageSize,
        callerId: this.filter.callerId,
        calleeId: this.filter.calleeId,
        start: new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss'),
        end: new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss'),
        taskGuid: this.filter.taskGuid,
      };
      let res = await this.$axios.post(`//${this.domains.trans}/pole/speak/log`, filter);
      if (res.code != 0) {
        return;
      }
      this.$set(this, 'history', res.data.list);
      this.filter.total = res.data.count;
    },
    pageSizeChange: function (size) {
      this.filter.index = 1;
      this.filter.size = size;
      this.getWillSieDevices();
    },
    pageIndexChange: function (index) {
      this.filter.index = index;
      this.getWillSieDevices();
    },
  }
}
</script>
<style scoped>
.media-player {
  /* visibility: hidden; */
  height: 0;
}
.symbol-download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.symbol-download img {
  margin-right: 10px;
  cursor: pointer;
}
.config-station-container {
  /* padding: 5px;  
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>
