<template>
  <div class="config-box">
    <div class="pole-area">
      <img class="pole-area-logo" :src="img.pole.logo" />
      <div class="pole-info" v-if="selectedNode.type == productCodes.station">
        <div class="pole-info-title"><img :src="img.pole.icon" />灯杆信息</div>
        <div class="pole-info-item">名称：{{selectedNode.name}}</div>
        <div class="pole-info-item">高度：{{selectedNode.height}}10 米</div>
      </div>
    </div>
    <div class="device-area">
      <div class="device-item" v-for="(item, idx) in devices" :key="idx">
        <component v-bind:is="getComByType(item.type)" :item="item" :tasks="getTaskByType(item.type)"></component>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import LightStationViewer from './LightStationViewer'
import EnvStationViewer from './EnvStationViewer'
import LedStationViewer from './LedStationViewer'
import VideoStationViewer from './VideoStationViewer'
import PowerStationViewer from './PowerStationViewer'
import BroadStationViewer from './BroadStationViewer'
import SpeakStationViewer from './SpeakStationViewer'
import ChargeStationViewer from './ChargeStationViewer'
import WifiStationViewer from './WifiStationViewer'
import GarbageStationViewer from './GarbageStationViewer'
import PhoneStationViewer from './PhoneStationViewer'
import ManholeStationViewer from './ManholeStationViewer'
import LockStationViewer from './LockStationViewer'
export default {
  name: 'PowerConfigViewer',
  components:{
  },
  props: {
  },
  data () {
    return {
      mediaTasks: [],
      devices: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
  },
  watch: {
    selectedNode(){
      this.getList();
    }
  },
  mounted: function(){
    this.getList();
  },
  methods: {
    getList: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleDevice`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'devices', res.data);
          if(res.data.filter(p => p.type == 302 || p.type == 304).length > 0){
            this.getAllMediaTasks();
          }else{
            this.$set(this, 'mediaTasks', []);
          }
        }
      });
    },
    getAllMediaTasks: function(){
      this.$axios.get(`//${this.domains.trans}/media/task`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'mediaTasks', res.data);
      })
    },
    getComByType: function(type){
      if(type == 199) return LightStationViewer;
      else if(type == 301) return VideoStationViewer;
      else if(type == 302) return LedStationViewer;
      else if(type == 303) return EnvStationViewer;
      else if(type == 304) return BroadStationViewer;
      else if(type == 305) return SpeakStationViewer;
      else if(type == 306) return WifiStationViewer;
      else if(type == 307) return ChargeStationViewer;
      else if(type == 309) return PowerStationViewer;
      else if(type == 310) return GarbageStationViewer;
      else if(type == 311) return LockStationViewer;
      else if(type == 312) return PhoneStationViewer;
      else if(type == 320) return ManholeStationViewer;
      else return null;
    },
    getTaskByType: function(type){
      if(type == 199) return [];
      else if(type == 301) return [];
      else if(type == 302) return this.mediaTasks;
      else if(type == 303) return [];
      else if(type == 304) return this.mediaTasks.filter(p => !p.needView);
      else if(type == 305) return [];
      else if(type == 306) return [];
      else if(type == 307) return [];
      else if(type == 309) return [];
      else if(type == 310) return [];
      else if(type == 311) return [];
      else if(type == 312) return [];
      else if(type == 320) return [];
      else return null;
    }
  }
}
</script>
<style scoped>
.config-box{
  height: 100% !important;
  /* border: solid 1px blue; */
  display: flex;
  border-radius: 8px;
}
.pole-area{
  /* border: solid 1px red; */
  width: 290px;
  height: 760px;
  flex: none;
  border: 1px solid #B4C5D6;
  box-shadow: 2px 4px 20px 0px rgba(27, 95, 168, 0.15);
  border-radius: 8px;
  position: relative;
  background-color: white;
}
.pole-area-logo{
  position: absolute;
  left: 37px;
  top: 72px;
}
.pole-info{
  position: absolute;
  bottom: 43px;
  right: 11px;
  width: 123px;
  height: 104px;
}
.pole-info-title{
  display: flex;
  align-items: center;
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-bottom: 10px;
}
.pole-info-title img{
  width: 14px;
  height: 16px;
  margin-right: 5px;
}
.pole-info-item{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
}
.device-area{
  /* border: solid 1px red; */
  margin-left: 10px;
  width: 10px;
  height: 100% !important;
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  overflow-y: auto !important;
}
.device-item{
  border: solid 1px red;
  width: 393px;
  height: 362px;
  border: 1px solid #B4C5D6;
  box-shadow: 2px 4px 20px 0px rgba(27, 95, 168, 0.1);
  border-radius: 8px;
  margin: 0 10px 20px 0;
  position: relative;
  background-color: white;
}
</style>