<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑唐沅云平台账户信息</div>
    <Form ref="form" :model="form" class="form" :rules="rules" :label-width="100">
      <FormItem prop="name" label="账户名称">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="rootUrl" label="接口IP">
        <Input ref="rootUrl" type="text" maxlength="15" v-model="form.rootUrl" placeholder="请输入接口IP">
        </Input>
      </FormItem>
      <FormItem prop="port" label="服务端口">
        <Input ref="port" type="number" maxlength="15" v-model="form.port" placeholder="请输入服务端口">
        </Input>
      </FormItem>
      <FormItem prop="appKey" label="appeui">
        <Input ref="appKey" type="text" maxlength="32" v-model="form.appKey" placeholder="请输入AppKey">
        </Input>
      </FormItem>
      <FormItem prop="appSecret" label="appauthkey">
        <Input ref="appSecret" type="text" maxlength="32" v-model="form.appSecret" placeholder="请输入AppSecret">
        </Input>
      </FormItem>
      <FormItem prop="port" label="大版本号">
        <Input ref="port1" type="number" maxlength="15" v-model="form.port1" placeholder="请输入服务端口">
        </Input>
      </FormItem>
      <FormItem prop="port" label="小版本号">
        <Input ref="port2" type="number" maxlength="15" v-model="form.port2" placeholder="请输入服务端口">
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
  export default {
    name: 'ModalRoleEdit',
    components:{
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default(){ return {};}
      }
    },
    data () {
      return {
        showModal: this.value,
        loading: false,
        isAdd: false,
        index: -1,
        form: {
          id: 0,
          name: '',
          rootUrl: '',
          port: 30003,
          appKey: '',
          appSecret: '',
          port1: 1,
          port2: 2,
        },
        rules: {
          rootUrl: [
            { required: true, message: '不能为空', trigger: 'blur' },
            { type: 'string', min: 7, max: 15, message: '接口IP长度必须7-15个字符', trigger: 'blur' }
          ],
          accessToken: [
            { required: true, message: '不能为空', trigger: 'blur' },
            { type: 'string', min: 32, max: 32, message: '访问token长度必须32个字符', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' },
            { type: 'string', min: 2, max: 20, message: '名称长度必须2 - 20个字符', trigger: 'blur' }
          ],
          appKey: [
            { required: true, message: '名称不能为空', trigger: 'blur' },
            { type: 'string', min: 16, max: 16, message: '名称长度必须16个字符', trigger: 'blur' }
          ],
          appSecret: [
            { required: true, message: '名称不能为空', trigger: 'blur' },
            { type: 'string', min: 32, max: 32, message: '名称长度必须32个字符', trigger: 'blur' }
          ],
        },
      }
    },
    watch: {
      // item(newVal){
        
      // },
      value(newVal) {
        this.showModal = newVal;
        if(this.showModal){
          this.isAdd = this.item.isAdd;
          if(!this.isAdd){
            this.form.id = this.item.data.id;
            this.form.rootUrl = this.item.data.rootUrl;
            this.form.port = this.item.data.port;
            this.form.name = this.item.data.name;
            this.form.appKey = this.item.data.appKey;
            this.form.appSecret = this.item.data.appSecret;
            this.form.port1 = this.item.data.port1;
            this.form.port2 = this.item.data.port2;
          }else{
            this.form.id = 0;
            this.form.name = '';
            this.form.rootUrl = '';
            this.form.port = 30003;
            this.form.appKey = '';
            this.form.appSecret = '';
            this.form.port1 = 1;
            this.form.port2 = 2;
          }
        }
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    methods: {
      ok: async function () {
        let result = await this.$refs.form.validate();
        if(!result){
          // this.hideLoading();
          return;
        }
        this.loading = true;
        if(this.form.appKey.length != 16){
          this.$Message.warning('appeui长度必须是16位');
          return;
        }
        if(this.form.appSecret.length != 32){
          this.$Message.warning('appauthkey长度必须是32位');
          return;
        }
        let res = await this.$axios.post(`//${this.domains.trans}/station/account/SaveZxklAccount`, this.form);
        this.loading = false;
        if(res.code !== 0){
          // this.$Message.error(res.data);
          return;
        }
        this.form.id = res.data;
        this.$Message.info('保存成功');
        setTimeout(() => {
          this.showModal = false;
          this.$emit('saved');
        }, 800);
      },
      cancel () {
        this.showModal = false;
        this.$refs.form.resetFields();
      },
    }
  }
</script>
<style scoped>
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>