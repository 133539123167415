<template>
<table border="0" cellspacing="0" cellpadding="0">
  <tr class="td-header">
    <td style="width: 50px">序号</td>
    <td style="width: 80px">灯杆</td>
    <td style="width: 50px">动作</td>
    <td style="width: 150px">时间</td>
    <td>内容</td>
  </tr>
  <tr v-for="n in 20" :key="n">
    <td class="tc">{{n}}</td>
    <td class="tc">灯杆{{n}}</td>
    <td class="tc">未确认</td>
    <td class="tc">{{new Date().format('yyyy-MM-dd HH:mm:ss')}}</td>
    <td class="p5">视频{{n}}离线</td>
  </tr>
</table>
</template>
<script>
export default {
  name: 'AlarmList',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
  },
}
</script>
<style scoped>
.td-header{
  background: #EAF1F9;
  text-align: center;
  /* height: 38px; */
}
table{
  width: 100%;
}
tr td{
  border-bottom: solid 1px #E9EDF4;
  height: 38px;
}
</style>