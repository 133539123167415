<template>
  <!--电源主控模块-->
  <div class="deviceImg-content">
    <img src="http://211.162.119.217:39008/M_Monitor/img/index/supply.png" alt="" class="leading-img">
    <div class="fixed-list-box">
      <div class="fixed-list-img">
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/lineA1.png" alt="" class="fixed-img fixed-img1">
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/lineB2.png" alt="" class="fixed-img fixed-img2">
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/lineA3.png" alt="" class="fixed-img fixed-img3">
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/lineA4.png" alt="" class="fixed-img fixed-img4">
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/lineA5.png" alt="" class="fixed-img fixed-img5">
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'deviceDetails',
  components: {
  },
  props: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {

  },
  methods: {
    setHigt: function(){},

  }
}
</script>
<style scoped>
.deviceImg-content {
  width: 100%;
  height: 100%;
  text-align: center;
}
.fixed-list-box {
  position: relative;
  height: 50px;
}
.fixed-img {
  position: absolute;
  top: -226px;
}
.fixed-img1 {
  margin-left: -624px;
}
.fixed-img2 {
  margin-left: -322px;
}
.fixed-img3 {
  margin-left: -3px;
}
.fixed-img4 {
  margin-left: 114px;
}
.fixed-img5 {
  margin-left: 231px;
}
</style>
