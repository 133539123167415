<template>
<div class="alarm-rule-container">
  <div class="query-data-area">
    <AuthButton opCode='pvd' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">批量删除</AuthButton>
  </div>
  <div class="alarm-data-area" ref="table1">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
      <vxe-column field="name" title="名称"></vxe-column>
      <vxe-column field="stationName" title="所在灯杆"></vxe-column>
      <vxe-column field="connectTypeName" title="接入方式" width="150"></vxe-column>
      <vxe-column field="deviceName" title="关联账号/设备" width="150"></vxe-column>
      <vxe-column field="code" title="序列号"></vxe-column>
      <vxe-column field="valid" title="验证码">
        <template #default="params">
          {{params.row.content.valid}}
        </template>
      </vxe-column>
      <vxe-column field="buildDate" title="安装日期" width="120"></vxe-column>
      <vxe-column width="150" title="操作" fixed="right">
        <template #header>
          <Button v-if="funCodes('pva')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">新增</Button>
          <Button v-if="funCodes('pvv')" size="small" type="primary" @click="getList" style="margin-right: 5px">刷新</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('pve')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">编辑</Button>
          <Button v-if="funCodes('pvd')" size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">删除</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalItemEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
    <!-- <ModalArgEdit v-model="showArgModal" /> -->
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalItemEdit from './ModalItemEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigEnvIndex',
  components:{
    ModalItemEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showArgModal: false,
      btns: [
        {code: 'updatePowerRated', name: '设定额定功率'},
        {code: 'updateCurrentRated', name: '设定额定电流'},
        {code: 'updateCurrentRate', name: '设定电流倍率'},
        {code: 'updateLeakageRate', name: '设定漏电阈值'},
        {code: 'updateSteelRate', name: '设定防盗阈值'},
      ]
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    }
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getCheckedItems: function(){//查询所有被选中的记录
      return this.$refs.chkTable.getCheckboxRecords();
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      // console.log('get selected', chks)
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, {list: list}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, {list: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleVideo`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
</style>