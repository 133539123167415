<template>
  <div class="config-station-container">
    <div class="station-search-area">
      <template v-if="devId == -1">
        名称或枪号：<Input v-model="filter.name" style="width: 300px; margin-right: 10px;" search clearable placeholder="名称或枪号" />
      </template>
      起始时间：
      <DatePicker v-model="filter.start" type="datetime" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      截止时间：
      <DatePicker v-model="filter.end" type="datetime" placeholder="请选择截止时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      <Button type="info" :loading="loading" @click="getList" style="margin-right: 10px;">查询</Button>
      <!-- <Button type="success" @click="exportData">导出</Button> -->
    </div>
    <div class="station-data-area" ref="table">
      <u-table :data="logs" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="filter.total" :page-size="filter.size" :current-page="filter.index" @handlePageSize="handlePageSize">
        <u-table-column type="index" label="序号" width="50"></u-table-column>
        <u-table-column prop="name" label="名称" sortable width="200">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="stationId" label="所在灯杆" sortable>
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="code" label="枪号" sortable>
          <template slot-scope="params">
            {{params.row.code}}
          </template>
        </u-table-column>
        <u-table-column prop="install" label="开始时间" sortable width="155">
          <template slot-scope="params">
            {{new Date(params.row.start*1000).format('yyyy-MM-dd HH:mm:ss')}}
          </template>
        </u-table-column>
        <u-table-column prop="install" label="结束时间" sortable width="155">
          <template slot-scope="params">
            {{params.row.end > 0 ? new Date(params.row.end*1000).format('yyyy-MM-dd HH:mm:ss') : '充电中'}}
          </template>
        </u-table-column>
        <u-table-column prop="money" label="金额" sortable>
          <template slot-scope="params">
            {{params.row.money}}
          </template>
        </u-table-column>
        <u-table-column prop="energy" label="能耗" sortable>
          <template slot-scope="params">
            {{params.row.energy}}
          </template>
        </u-table-column>
      </u-table>
    </div>
    <!-- <ModalSpeakEdit v-model="showEditModal" :item="editItem" :types="poleChargeTypes" /> -->
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
// import ModalSpeakEdit from './ModalSpeakEdit'
export default {
  name: 'ChargeLogIndex',
  components: {
    // ModalSpeakEdit,
  },
  props: {
    devId: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: { isAdd: true, index: -1, data: {} },
      filter: {
        name: '',
        paramId: 0,
        start: '',
        end: '',
        index: 0,
        size: 15,
        total: 0,
      },
      poleChargeTypes: {
        1: '唐沅平台设备',
      },
      loading: false,
      logs: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStationIds: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    devId() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    let now = new Date();
    now.setHours(23, 59, 59, 0)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now = new Date();
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
  },
  methods: {
    getList: function () {
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { sids: this.selectedStationIds, start: this.filter.start, end: this.filter.end, name: this.filter.name, index: this.filter.index, size: this.filter.size };
      this.loading = true;
      if (this.devId != -1) {
        params.sids = [this.devId];
        params.name = "";
      }
      this.$axios.post(`//${this.domains.trans}/pole/charge/log`, params).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'logs', res.data.list);
          this.filter.total = res.data.count;
        }
      });
    },

    handlePageSize: function (params) {
      this.filter.size = params.size;
      this.filter.index = params.page;
      this.getList();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
  }
}
</script>
<style scoped>
.config-station-container {
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>