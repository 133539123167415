<template>
  <div class="history-con">
    <div class="history-left-box">
      <table>
        <tr>
          <td class="tr nowrap">摄像头：</td>
          <td>
            <Select v-model="filter.videoParamId" style="width: 175px; margin-right: 10px;">
              <template v-for="(item, idx) in devices">
                <Option :value="item.id" :key="idx">{{item.name}}</Option>
              </template>
            </Select>
          </td>

          <td class="tr nowrap">查询日期：</td>
          <td>
            <DatePicker v-model="filter.start" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
          </td>
          <td>
            <Tooltip content="查询日期的时间范围为五分钟">
              <img :src="img.mapAlarmTitle" alt="">
            </Tooltip>
          </td>
          <td colspan="2" class="tc"><a href="javascript:void(0);" class="searchBut" @click="getPlayUrl"><img :src="img.pole.speak.lookup" />查询</a></td>
        </tr>
      </table>
    </div>
    <div class="history-right-box">
      <div id="videoPlayer" class="history-right-video"></div>
    </div>
    <!-- <div class="history-right-box">
      <video id="myVideo11" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" width="800" height="500" :playsinline="false">
        <source type="application/x-mpegURL" v-show="iframeSrc" :src="iframeSrc">
      </video>
      <iframe id="videoPlayer" class="history-right-video" src="https://open.ys7.com/v3/openlive/F85059284_1_1.m3u8?expire=1663294044&id=490463643159244800&t=4b3ce685bb570a959b00961fe01dd90cdfde080fcf1aa22e0796e0173c258a22&ev=100"></iframe>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EZUIKit from 'ezuikit-js';
// import videojs from 'video.js';
// import 'videojs-contrib-hls';
export default {
  name: 'Home',
  components: {
  },
  props: {
    item: {
      type: Object,
      default() { return { id: 100, img: this.testImg, name: '社区00000001', time: '2022-06-14 15:25:25' } }
    }
  },
  data() {
    return {
      iframeSrc: "https://open.ys7.com/v3/openlive/F85059284_1_1.m3u8?expire=1663384474&id=490842932648083457&t=36b8beff4c8445650a28ce088dff78710a989e272af169baa602c29cfc60c824&ev=100",
      searchCon: "",
      size: 6,
      testImg: require("@/assets/video-1.jpeg"),
      filter: {
        videoParamId: 0,
        start: '',
        end: '',
      },
      devices: [],
      list: [],
      subs: [],
      play: -1,
      player: null
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
  },
  mounted: function () {
    this.getVideos();
    let date = new Date();
    this.filter.start = date;
    let min = date.getMinutes();
    this.filter.end = date.getMinutes(min + 5);
  },
  methods: {
    getVideos: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleVideo`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        res.data.map(p => {
          if(!p.content){
            p.content = {};
          }
          if(!p.content.tasks){
            p.content.tasks = [];
          }
        })
        this.$set(this, 'devices', res.data);
        if (this.devices.length > 0) {
          this.filter.videoParamId = this.devices[0].id;
        } else {
          this.filter.videoParamId = 0;
        }
      })
    },
    // getVideo() {
    //   this.player = videojs('myVideo11', {
    //     bigPlayButton: true, //显示播放按钮
    //     controls: true,
    //     textTrackDisplay: false,
    //     posterImage: true,
    //     errorDisplay: false,
    //     liveDisplay: false,
    //     loop: true,
    //     controlBar: true,
    //     progressControl: true,
    //   }, function () {
    //     if (this.iframeSrc) {
    //       this.play();
    //     }
    //   })
    // },
    getPlayUrl: function () {
      this.closePlayer();
      let start = new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss');
      let today = new Date(this.filter.start);
      let end = new Date(today.setMinutes(today.getMinutes() + 5)).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/pole/video/Record`, { id: this.filter.videoParamId, start, end }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.previewVideo(res.data);
      });
    },
    previewVideo: function ({ url, token }) {
      let id = 'videoPlayer';
      let v = document.getElementById(id);
      // let token = item.token;
      let rect = v.getBoundingClientRect();
      this.player = new EZUIKit.EZUIKitPlayer({
        id: id,
        accessToken: token,
        url: url,
        autoplay: true,
        width: rect.width,
        height: rect.height,
      });
      v.style.overflow = 'hidden';
    },
    closePlayer: function () {
      if (this.player) {
        this.player.stop();
        this.player = null;
        let v = document.getElementById('videoPlayer');
        v.removeChild(v.childNodes[0]);
      }
    },
    getList: function () {
      let start = new Date(this.filter.start);
      let today = new Date(this.filter.start);
      let end = new Date(today.setMinutes(today.getMinutes() + 5))
      this.$axios.post(`//${this.domains.trans}/pole/video/Logs`, { id: this.filter.videoParamId, start, end }).then(res => {
        if (res.code != 0) {
          this.$set(this, "list", []);
          return;
        }
        this.$set(this, "list", res.data);
        this.setSubs(1);
      });
    },
    setSubs: function (pageIndex) {
      this.$set(this, 'subs', this.list.slice(this.size * (pageIndex - 1), this.size * pageIndex));
      this.play = -1;
    },
    toolAction: function (params) {
      console.log("tool action", params.open, params.tool);
    },
  },
}
</script>
<style scoped>
.history-con {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.searchBut {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  margin-left: 19px;
  width: 80px;
}
.searchBut img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 4px;
}
.history-left-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #24334a;
  margin-bottom: 19px;
}
.history-left-list {
  height: 635px;
  flex: auto;
  overflow: hidden;
  margin: 10px 0;
  position: relative;
}
.history-list-box {
  display: flex;
  flex-direction: inherit;
  margin-bottom: 11px;
}
.history-list-box.active {
  background-color: rgba(0, 126, 255, 0.15);
}
.history-list-box:hover {
  background-color: rgba(0, 128, 255, 0.062);
}
.history-list-cover {
  width: 129px;
  height: 84px;
  border-radius: 4px;
}
.history-list-cover img {
  width: 100%;
  height: 100%;
}
.history-list-info {
  display: flex;
  flex-direction: column;
  margin-left: 19px;
}
.history-list-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
}
.history-list-time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.history-list-tools {
  margin-top: 20px;
}
.history-list-tools img {
  margin-right: 12px;
  cursor: pointer;
}
.history-left-box {
  width: 300px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.history-right-box {
  /* border: solid 1px red; */
  width: 100%;
  flex: auto;
  height: 100%;
}
.history-right-video {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  background: #e9edf4;
  border: 4px solid #acc8e8;
  border-radius: 12px;
}
.pager div input {
  width: 100px;
}
.empty-text {
  text-align: center;
}
.empty-img {
  width: 100%;
  margin: 50px auto 0;
}
.ios-help-empty {
  color: #f00;
}
</style>
