<template>
  <!-- 外接电管理 -->
  <div class="pole-container">
    <template v-if="selectedNode.type == productCodes.station">
      <baiMaIndex class="content-item" v-if="currentPower && currentPower.protocol == 'baima'" :power="currentPower" />
      <baseIndex class="content-item" v-else :devices="devices" />
    </template>
    <groupIndex v-else :devices="devices" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import baseIndex from './base/deviceDetails'
import baiMaIndex from './base/deviceBaiMaDetails'
import groupIndex from './group/Index'
export default {
  name: 'Home',
  components: {
    baseIndex,
    groupIndex,
    baiMaIndex,
  },
  data() {
    return {
      devices: [],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
    currentPower: function(){
      if(this.selectedNode.type !== this.productCodes.station)return null;
      let cs = this.devices.filter(p => p.stationId == this.selectedNode.id);
      if(cs.length == 0)return null;
      return cs[0];
    },
  },
  watch: {
    selectedNode(){
      this.getItems();
    }
  },
  mounted: function () {
    this.getItems();
  },
  destroyed: function () {

  },
  methods: {
    getItems: function () {
      let stationId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPolePower`, {groupId, stationId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        // res.data.map(p => {
        //   if(!p.lastData){
        //     p.lastData = {u: 3};
        //   }
        // })
        this.$set(this, 'devices', res.data);
      // console.log(this.selectedNode, this.devices, this.currentPower)
      })
    },
  }
}
</script>
<style scoped>
.pole-container {
  background: #e3e9ef;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}
</style>
