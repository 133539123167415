<template>
  <div class="video-control">
    <div class="video-title">摄像头信息 <span v-if="devices.length!=0"><img class="device-item-header-status-icon" :src="player ? img.pole.online : img.pole.offline" />{{player? '在线' : '离线'}}</span></div>
    <div class="video-con" v-if="devices.length>0">
      <div id="vehicleId" class="video-player" :key="timer"></div>
    </div>
    <div class="video-con" v-if="devices.length==0">
      暂无摄像头
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EZUIKit from 'ezuikit-js';
export default {
  name: 'Home',
  components: {
  },
  props: {
    devices: {
      type: Array,
      default() { return []; }
    },
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  data() {
    return {
      timer: 0,
      player: null
    }
  },
  watch: {
    devices() {
      this.timer = new Date().getTime();
      this.urlAjax();
    }
  },
  mounted: function () {
    this.urlAjax();
  },
  methods: {
    urlAjax: async function () {
      if (this.devices.length < 0) { return false }
      let ajaxData = this.devices[0];
      this.player = null;
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/Preview`, { ids: [ajaxData.id] });
      this.timer = new Date().getTime();
      if (res.code !== 0) {
        return;
      }
      setTimeout(() => {
        let id = "vehicleId";
        let v = document.getElementById(id);
        let token = res.data[ajaxData.id].token;
        let url = res.data[ajaxData.id].content;
        let rect = v.getBoundingClientRect();
        this.player = new EZUIKit.EZUIKitPlayer({
          id: id,
          accessToken: token,
          url: url,
          autoplay: true,
          width: rect.width,
          height: rect.height,
        });
        v.style.overflow = 'hidden';
      }, 100);
    },
  }
}
</script>
<style scoped>
.video-control {
  height: calc(100%);
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
}
.video-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.video-title span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-left: 15px;
}
.video-title img {
  vertical-align: middle;
  margin-right: 5px;
}
.video-con {
  width: 100%;
  height: calc(100% - 35px);
}
.video-player {
  width: 100%;
  height: 100%;
}
</style>
