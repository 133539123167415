<template>
  <div class="device-item-content" ref="table">
    <div class="device-table">
      <u-table :data="devices" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column type="index" width="60" label="序号" sortable fit align="center"></u-table-column>
        <u-table-column prop="name" label="名称" sortable fit align="center">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="alarm" label="状态" sortable fit align="center">
          <template slot-scope="scope">
            {{scope.row.alarm?"报警":"正常"}}
          </template>
        </u-table-column>
        <u-table-column prop="lastData.acv" label="交流电压" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{scope.row.lastData.acv}}
          </template>
        </u-table-column>
        <u-table-column prop="lastData.aci" label="交流电流" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{scope.row.lastData.aci}}
          </template>
        </u-table-column>
        <u-table-column prop="lastData.acp" label="交流功率" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{scope.row.lastData.acp}}
          </template>
        </u-table-column>

        <u-table-column prop="lastData.poe" label="是否启用POE" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{isPoeWork(scope.row.lastData.poe)}}
          </template>
        </u-table-column>
        <u-table-column label="1号槽" align="center">
          <u-table-column prop="lastData.k1" label="继电器输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.k1?"关":"开"}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.s1" label="开入量输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.s1?"关":"开"}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p1s" label="工作状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isGrooveWork(scope.row.lastData.p1s)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p1m" label="主电源类型" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.p1m)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.dc1m" label="主电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc1m)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.dc1s" label="从电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc1s)}}
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column label="2号槽" align="center">
          <u-table-column prop="lastData.k2" label="继电器输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.k2?"关":"开"}}
            </template>
          </u-table-column>

          <u-table-column prop="lastData.s2" label="开入量输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.s2?"关":"开"}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p2s" label="工作状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isGrooveWork(scope.row.lastData.p2s)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p2m" label="主电源类型" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.p2m)}}
            </template>
          </u-table-column>

          <u-table-column prop="lastData.dc2m" label="主电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc2m)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.dc2s" label="从电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc2s)}}
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column label="3号槽" align="center">
          <u-table-column prop="lastData.k3" label="继电器输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.k3?"关":"开"}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.s3" label="开入量输出状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{scope.row.lastData.s3?"关":"开"}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p3s" label="工作状态" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isGrooveWork(scope.row.lastData.p3s)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.p3m" label="主电源类型" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.p3m)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.dc3m" label="主电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc3m)}}
            </template>
          </u-table-column>
          <u-table-column prop="lastData.dc3s" label="从电源电压" sortable fit align="center" width="155">
            <template slot-scope="scope">
              {{isPowerType(scope.row.lastData.dc3s)}}
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column prop="lastData.pv" label="POE输出电压" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{isPowerType(scope.row.lastData.pv)}}
          </template>
        </u-table-column>
        <u-table-column prop="lastData.temp" label="工作温度" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{isPowerType(scope.row.lastData.temp)}}
          </template>
        </u-table-column>
        <u-table-column prop="lastData.time" label="发生时间" sortable fit align="center" width="155">
          <template slot-scope="scope">
            {{isPowerType(scope.row.lastData.time)}}
          </template>
        </u-table-column>

        <u-table-column width="220" label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <a href="javascript:void(0);" class="cameraInfo-tab-but color1" @click="history(scope.row)"><img :src="img.pole.speak.record" />查看详情</a>
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'deviceList2',
  components: {
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {
      tabData: [],
      tabHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    // ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {},
  mounted: function () {
  },
  methods: {
    isPoeWork(key) {
      let name = "不启用";
      switch (key) {
        case 0:
          name = "不启用";
          break;
        case 1:
          name = "2号槽供电";
          break;
        case 2:
          name = "3号槽供电";
          break;
      }
      return name;
    },
    isGrooveWork(key) {
      let name = "独立工作";
      switch (key) {
        case 0:
          name = "独立工作";
          break;
        case 1:
          name = "主电源工作";
          break;
        case 2:
          name = "从电源工作";
          break;
      }
      return name;
    },
    isPowerType(key) {
      let name = "空或坏";
      switch (key) {
        case 0:
          name = "空或坏";
          break;
        case 1:
          name = "5V";
          break;
        case 2:
          name = "12V";
          break;
        case 3:
          name = "24V";
          break;
        case 4:
          name = "48V";
          break;
        case 5:
          name = "POE";
          break;
        case 6:
          name = "未初始化";
          break;
      }
      return name;
    },
    history(params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.stationId, type: this.productCodes.station });
      // this.$store.commit('auth/switchStationTabName', 'power');
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 100;
    },

  }
}
</script>
<style scoped>
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
}
.tool-type {
  width: 168px;
  height: 32px;
  margin: 0 10px 0 5px;
}
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
.cameraInfo-tab-but {
  padding: 0px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin: 0 5px;
}
.cameraInfo-tab-but img {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 3px;
}

.cameraInfo-tab-but.color1 {
  background: #21a4f8;
}
.cameraInfo-tab-but.color2 {
  background: #3cbf6e;
}
.cameraInfo-tab-but.color3 {
  background: #fd5353;
}
.cameraInfo-tab-but.color4 {
  background: #6485fd;
}
.device-table {
  margin-top: 20px;
}
.device-table /deep/ .plTableBox .el-table th {
  padding: 5px 0;
  background: #eaf1f9;
}
.device-table /deep/ .plTableBox .el-table td {
  padding: 5px 0;
}
</style>
