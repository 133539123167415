  <!--电源主控模块-->
  <template>
  <div class="supply-public">
    <div class="supply-top">电源主控模块</div>
    <div class="supply-con">
      <div class="supply-portList" style="margin-top: 20px;">
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">端口1</span> <span class="supply-portCon">C1节点通信链路</span></div>
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">端口2</span> <span class="supply-portCon">C2节点通信链路</span></div>
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">端口3</span> <span class="supply-portCon">C3节点通信链路</span></div>
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">端口4</span> <span class="supply-portCon">C4节点通信链路</span></div>
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">GND</span> <span class="supply-portCon" style="background-color: rgb(152, 169, 186);">确保设备接触公共地</span></div>
        <div class="supply-list"><img src="http://211.162.119.217:39008/M_Monitor/img/icon/port2.png" alt=""> <span class="supply-port">+12V</span> <span class="supply-portCon">x1,x2,x3,x4传感器</span></div>
      </div>
      <div class="supply-showAalue"><span class="supply-name">设备内温度</span> <span class="supply-value">51.00℃</span></div>
      <div class="supply-mt5"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice mt10">查询报警限</a></div>
      <div class="supply-mt5" style="text-align: center; margin-top: 120px;">
        <span class="supply-butName">K1</span>
        <img src="http://211.162.119.217:39008/M_Monitor/img/index/open.png" alt="" class="supply-v-align-m supply-ml10">
      </div>
      <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置时间表</a> <a href="javaScript:" class="supply-butPolice ml20">查询时间表</a></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'supply4',
  components: {
  },
  props: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {

  },
  methods: {
    setHigt: function(){},

  }
}
</script>
<style scoped>
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
}
.supply-public {
  width: 280px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
.supply-top {
  width: 100%;
  background-color: #05c29c;
  height: 60px;
  border-radius: 8px 8px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}
.supply-con {
  padding: 0 10px;
  text-align: center;
}
.supply-portList {
  width: 100%;
  height: 230px;
  background: #ffffff;
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  border-radius: 6px;
  margin-top: 20px;
}
.supply-list {
  margin-top: 14px;
  text-align: left;
  text-indent: 21px;
}
.supply-list img {
  width: 12px;
  vertical-align: middle;
}
.supply-port {
  color: #324252;
  font-size: 14px;
  margin: 0 7px;
}
.supply-portCon {
  color: #fff;
  font-size: 12px;
  width: 133px;
  height: 18px;
  background: #3d5872;
  border-radius: 9px;
  line-height: 18px;
  display: inline-block;
}
.supply-showAalue {
  width: 98%;
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  border-radius: 6px;
  margin: 10px auto 0;
  position: relative;
  height: 35px;
  line-height: 35px;
}
.supply-name {
  font-size: 14px;
  color: #324252;
  text-indent: -40px;
  display: inline-block;
}
.supply-value {
  font-size: 20px;
  color: #021629;
}
.supply-butPolice {
  width: 96px;
  height: 26px;
  background: linear-gradient(0deg, #3965c8, #51d1fa);
  box-shadow: 0px 1px 4px 0px rgb(32 39 43 / 10%);
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
  color: #ffffff;
  line-height: 26px;
}
.supply-butName {
  vertical-align: super;
  margin-right: 10px;
}
</style>
