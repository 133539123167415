<template>
  <div class="device-item-content">
    <div class="device-item-header">
      <img class="device-item-header-icon" :src="img.pole.env.icon" title="环境监测" />
      <span class="device-item-header-title">{{item.name}}</span>
      <!-- {{new Date(item.common.fireTime).format('yyyy-MM-dd HH:mm:ss')}} -->
      <img class="device-item-header-status-icon" :src="item.online ? img.pole.online : img.pole.offline" />
      <span class="device-item-header-status">{{item.online ? '在线' : '离线'}}</span>
    </div>
    <div class="env-temp">
      <img class="mr10" :src="img.pole.env.flag" />
      <div style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #506179;">温度 {{img.val(item.lastData.t, 1)}} ℃</div>
    </div>
    <Button type="success" style="position: absolute;top: 70px;right: 28px;width:40px;height:40px;border:solid 0 red;" @click="doReadData">召</Button>
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="env-list" :key="lightRealDataRefresh">
      <div class="env-item mr10">
        <div class="env-item-title">湿度</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.speed" /> -->
          <div>{{img.val(item.lastData.h, 1)}}RH</div>
        </div>
      </div>
      <div class="env-item">
        <div class="env-item-title">大气压力</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.direct" /> -->
          <div>{{img.val(item.lastData.pre, 0)}}hPa</div>
        </div>
      </div>
      <div class="env-item mr10">
        <div class="env-item-title">光照强度</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.direct" /> -->
          <div>{{img.val(item.lastData.lux, 0)}}Lux</div>
        </div>
      </div>

      <div class="env-item">
        <div class="env-item-title">雨量</div>
        <div class="env-item-content">
          <div>{{img.val(item.lastData.rain, 0)}}mm/h</div>
        </div>
      </div>
      <div class="env-item mr10">
        <div class="env-item-title">风向</div>
        <div class="env-item-content">
          <div>{{getWindArgcName(item.lastData.da)}}</div>
        </div>
      </div>
      <div class="env-item">
        <div class="env-item-title">风速</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.direct" /> -->
          <div>{{img.val(item.lastData.spd, 1)}} m/s</div>
        </div>
      </div>
      <div class="env-item mr10">
        <div class="env-item-title">PM2.5</div>
        <div class="env-item-content">
          <div>{{img.val(item.lastData.pm25, 1)}} μg/m³</div>
        </div>
      </div>
      <div class="env-item">
        <div class="env-item-title">PM10</div>
        <div class="env-item-content">
          <div>{{img.val(item.lastData.pm10, 1)}} μg/m³</div>
        </div>
      </div>
      <div class="env-item mr10">
        <div class="env-item-title">CO₂</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.hum" /> -->
          <div>{{img.val(item.lastData.co2, 1)}} ppm</div>
        </div>
      </div>
      <div class="env-item">
        <div class="env-item-title">噪声</div>
        <div class="env-item-content">
          <!-- <img :src="img.pole.env.hum" /> -->
          <div>{{img.val(item.lastData.n, 1)}} dB</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'EnvStationViewer',
  components: {
  },
  props: {
    item: {
      type: Object,
      default() { return {} },
    },
  },
  data() {
    return {
      move: false,
      offset: 0,
      bottom: 0,
      start: 0,
      spin: {
        show: false,
        content: '加载中...',
      },
      lightRealDataRefresh: 0,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands']),
    online: function(){
      if(!this.item)return false;
      return this.item.online;
    },
  },
  watch: {
    cmdResultRefresh() {
      // console.log(this.item.lastData)
      let cmds = this.commands.filter(p => p.id == this.item.id);
      if (cmds.length == 0) return;
      let cmd = cmds[0];
      switch (cmd.status) {
        case 0:
          this.showSpin(`${cmd.result}`);
          break;
        case 1:
          this.showSpin('等待回复');
          break;
        case 2:
          this.showSpin('指令超时');
          break;
        case 3:
          this.showSpin(`执行失败：${cmd.result}`);
          setTimeout(this.hideSpin, 2000);
          break;
        case 9:
          // if(cmd.code == 'forceRead'){
          //   this.item.fireTime = new Date().getTime();
          //   this.item.lastData.s1 = cmd.content.s1;
          //   this.item.lastData.k1 = cmd.content.k1;
          //   this.item.lastData.pv = cmd.content.poeV;
          //   this.item.lastData.poe = cmd.content.hasPoe;
          //   this.item.lastData.acv = cmd.content.acV;
          //   this.item.lastData.aci = cmd.content.acI;
          //   this.item.lastData.temp = cmd.content.temp;
          //   this.item.lastData.acp = cmd.content.acP;
          // }
            // console.log('cmd result', this.item, cmd)
          this.lightRealDataRefresh++;
          this.showSpin(`执行成功`);
          setTimeout(this.hideSpin, 1000);
          break;
      }
    }
  },
  mounted: function () {
  },
  methods: {
    doReadData: function () {
      // console.log('force read data', this.item)
      let devs = [];
      devs.push({
        id: this.item.id,
        deviceId: this.item.deviceId,
        code: 'forceRead',
        name: '召测',
        args: {}
      });
      this.showSpin('等待指令回复');
      this.$store.dispatch('cmd/sendCommands', devs);
    },
    showSpin: function (msg) {
      this.spin.content = msg;
      this.spin.show = true;
    },
    hideSpin: function () {
      this.spin.show = false;
    },
    getWindArgcName: function (argc) {
      if (argc < 0) return '-';
      if (argc <= 22.5 || argc >= 337.5) return '北';
      if (argc >= 22.5 && argc <= 67.5) return '东北';
      if (argc >= 67.5 && argc <= 112.5) return '东';
      if (argc >= 112.5 && argc <= 157.5) return '东南';
      if (argc >= 157.5 && argc <= 202.5) return '南';
      if (argc >= 202.5 && argc <= 247.5) return '西南';
      if (argc >= 247.5 && argc <= 292.5) return '西';
      if (argc >= 292.5 && argc <= 337.5) return '西北';
    }
  }
}
</script>
<style scoped>
.env-temp {
  /* border: solid 1px red; */
  position: absolute;
  top: 72px;
  left: 141px;
  width: 150px;
  height: 51px;
  display: flex;
  align-items: center;
}
.env-air {
  /* border: solid 1px red; */
  position: absolute;
  top: 72px;
  left: 130px;
  width: 200px;
  height: 51px;
}
.env-list {
  /* border: solid 1px red; */
  position: absolute;
  top: 140px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
}
.env-item {
  /* border: solid 1px red; */
  width: 168px;
  margin-bottom: 5px;
  /* margin: 5px 10px; */
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}
.env-item-title {
  /* border: solid 1px red; */
  width: 60px;
  height: 32px;
  flex: none;
  text-align: center;
  text-align-last: justify;
  margin-right: 10px;
  line-height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
}
.env-item-content {
  /* border: solid 1px red; */
  width: 97px;
  height: 32px;
  flex: none;
  background: #eef3f8;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.env-item-content img {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  flex: none;
}
.env-item-content div {
  margin: 0 10px;
  flex: auto;
}
.device-item-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header {
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon {
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
