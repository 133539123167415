<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">编辑联动规则</div>
    <div class="tabs-control">
      <div class="tabs-header">
        <div :class="['tab-item', tabName == 'cfg' ? 'tab-active' : '']" @click="switchTab('cfg')">联动规则信息</div>
        <div :class="['tab-item', tabName == 'con' ? 'tab-active' : '']" @click="switchTab('con')">触发条件</div>
        <div :class="['tab-item', tabName == 'act' ? 'tab-active' : '']" @click="switchTab('act')">触发动作</div>
      </div>
      <div v-if="tabName == 'cfg'" class="tabs-content">
        <Form ref="form" :model="form" :label-width="80">
          <FormItem prop="name" label="规则名称">
            <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称">
            </Input>
          </FormItem>
          <FormItem prop="remark" label="有效时段1">
            <div class="edit-inline">
              起：<TimePicker v-model="form.start1" format="HH:mm" style="width: 168px"></TimePicker>
              止：<TimePicker v-model="form.end1" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="有效时段2">
            <div class="edit-inline">
              起：<TimePicker v-model="form.start2" format="HH:mm" style="width: 168px"></TimePicker>
              止：<TimePicker v-model="form.end2" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="有效时段3">
            <div class="edit-inline">
              起：<TimePicker v-model="form.start3" format="HH:mm" style="width: 168px"></TimePicker>
              止：<TimePicker v-model="form.end3" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="有效时段4">
            <div class="edit-inline">
              起：<TimePicker v-model="form.start4" format="HH:mm" style="width: 168px"></TimePicker>
              止：<TimePicker v-model="form.end4" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="weeks" label="有效日期">
            <CheckboxGroup v-model="form.weeks">
              <Checkbox v-for="n in 7" :key="n" :label="n">{{weeks[n]}}</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem prop="description" label="规则说明">
            <Input ref="name" type="textarea" v-model="form.description" placeholder="请输入规则说明" :rows="6">
            </Input>
          </FormItem>
        </Form>
      </div>
      <div v-else-if="tabName == 'con'" class="tabs-content">
        <div class="con-item" v-for="(item, idx) in form.cons" :key="idx">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="width: 100px" class="tr">{{idx+1}}、条件名称</td>
              <td colspan="2"><Input ref="name" type="text" v-model="item.name" placeholder="请输入名称"></Input></td>
              <td class="tr">
                <Button type="success" @click="addConBig(form.cons)" style="margin-right:10px;">新增</Button>
                <Button type="error" @click="removeCon(form.cons, idx)" style="">移除</Button>
              </td>
            </tr>
            <tr>
              <td style="width: 100px" class="tr">条件说明</td>
              <td colspan="3"><Input ref="name" type="textarea" v-model="item.description" placeholder="请输入规则说明" :rows="3"></Input></td>
            </tr>
            <tr>
              <td style="width: 100px" class="tr">选择站点</td>
              <td style="width: 300px">
                <Select v-model="item.stationId" @on-change="conStationIdChanged(item)">
                  <template v-for="(ss, idx) in stations">
                    <Option :value="ss.id" :key="idx">{{ss.pathName}} -> {{ss.name}}</Option>
                  </template>
                </Select>
              </td>
              <td style="width: 100px" class="tr">选择属性</td>
              <td>
                <Select v-model="item.paramId" @on-change="conParamIdChanged(item)">
                  <template v-for="(pp, idx) in devices">
                    <Option v-if="pp.stationId == item.stationId" :value="pp.id" :key="idx">[{{poleParamTypeName(pp.type)}}]: {{pp.name}}</Option>
                  </template>
                </Select>
              </td>
            </tr>
            <tr v-for="(con, cidx) in item.items" :key="cidx">
              <td class="tr">条件{{cidx+1}}、</td>
              <td colspan="3">
                <div class="cons-subs">
                  <Select v-model="con.code" style="width: 200px;flex:none;margin-right:10px;">
                    <template v-for="(ss, idx) in currentCodes(selectedParamType(item.paramId))">
                      <Option :value="ss.code" :key="idx">{{ss.name}}</Option>
                    </template>
                  </Select>
                  <Select v-model="con.op" style="width: 100px;flex:none;margin-right:10px;">
                    <Option value="lt">小于</Option>
                    <Option value="le">小于或等于</Option>
                    <Option value="eq">等于</Option>
                    <Option value="ge">大于或等于</Option>
                    <Option value="gt">大于</Option>
                  </Select>
                  <Input type="number" v-model="con.val" placeholder="对比值" style="width: 100px;flex:none;margin-right:10px;"></Input>
                  <!-- 持续<Input type="number" v-model="con.keep" placeholder="持续时间" style="width: 100px;flex:none;margin:0 10px;"></Input>秒 -->
                  <Button type="success" @click="addCon(item.items)" style="width: 40px;flex:none;margin:0 10px;">+</Button>
                  <Button type="error" @click="removeCon(item.items, cidx)" style="width: 40px;flex:none;margin-right:10px;">-</Button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else-if="tabName == 'act'" class="tabs-content">
        <div class="con-item" v-for="(item, idx) in form.acts" :key="idx">
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="width: 100px" class="tr">{{idx+1}}、动作名称</td>
              <td colspan="2"><Input ref="name" type="text" v-model="item.name" placeholder="请输入名称"></Input></td>
              <td class="tr">
                <Button type="success" @click="addActBig(form.acts)" style="margin-right:10px;">新增</Button>
                <Button type="error" @click="removeCon(form.acts, idx)" style="">移除</Button>
              </td>
            </tr>
            <tr>
              <td style="width: 100px" class="tr">动作说明</td>
              <td colspan="3"><Input ref="name" type="textarea" v-model="item.description" placeholder="请输入规则说明" :rows="3"></Input></td>
            </tr>
            <tr>
              <td style="width: 100px" class="tr">选择站点</td>
              <td style="width: 300px">
                <Select v-model="item.stationId" @on-change="conStationIdChanged(item)">
                  <template v-for="(pp, idx) in stations">
                    <Option :value="pp.id" :key="idx">{{pp.pathName}} -> {{pp.name}}</Option>
                  </template>
                </Select>
              </td>
              <td style="width: 100px" class="tr">选择属性</td>
              <td>
                <Select v-model="item.paramId" @on-change="actParamIdChanged(item)">
                  <template v-for="(pp, idx) in devices">
                    <Option v-if="pp.stationId == item.stationId" :value="pp.id" :key="idx">[{{poleParamTypeName(pp.type)}}]: {{pp.name}}</Option>
                  </template>
                </Select>
              </td>
            </tr>
            <tr>
              <td class="tr">执行指令</td>
              <td colspan="3">
                <Select v-model="item.code" style="width: 200px;flex:none;margin-right:10px;">
                  <template v-for="(ss, idx) in currentActions(selectedParamType(item.paramId))">
                    <Option :value="ss.code" :key="idx">{{ss.name}}</Option>
                  </template>
                </Select>
              </td>
            </tr>
            <tr>
              <td style="width: 100px" class="tr">指令参数</td>
              <td colspan="3">
                <div class="cons-subs" v-if="selectedParamType(item.paramId) == 3 && item.code == 'handControl'">
                  <div style="display: flex;align-items:center;">
                    <i-switch v-model="item.args.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
                      <span slot="open">开灯</span>
                      <span slot="close">关灯</span>
                    </i-switch>
                    <DatePicker type="datetime" v-model="item.args.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
                  </div>
                </div>
                <div class="cons-subs" v-else-if="selectedParamType(item.paramId) == 199 && item.code == 'handSingle'">
                  亮度：<Slider v-model="item.args.bright" style="width: 200px;" class="mr10 ml10"></Slider>
                  色温：<Slider v-model="item.args.color" style="width: 200px;" class="mr10 ml10"></Slider>
                  延时至：<DatePicker type="datetime" v-model="item.args.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
                </div>
                <div class="cons-subs" v-else-if="selectedParamType(item.paramId) == 199 && item.code == 'handGroup'">
                  分组号：<Input v-model="item.args.group" type="number" number style="width: 80px; height: 32px" class="mr10 ml10"></Input>
                  亮度：<Slider v-model="item.args.bright" style="width: 150px;" class="mr10 ml10"></Slider>
                  色温：<Slider v-model="item.args.color" style="width: 150px;" class="mr10 ml10"></Slider>
                  延时至：<DatePicker type="datetime" v-model="item.args.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
                </div>
                <div class="cons-subs" v-else-if="[301].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'goto'">
                  点号：<Input v-model="item.args.point" type="number" number style="width: 120px; height: 32px"></Input>
                </div>
                <div class="cons-subs" v-else-if="[302].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'bright'">
                  亮度：<Slider v-model="item.args.bright" :min="0" :max="100" style="width: 400px;"></Slider>
                </div>
                <div class="cons-subs" v-else-if="[302].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'volume'">
                  音量：<Slider v-model="item.args.volume" :min="0" :max="100" style="width: 400px;"></Slider>
                </div>
                <div class="cons-subs" v-else-if="[302].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'play'">
                  <Select v-model="item.args.mediaId" style="width: 200px;flex:none;margin-right:10px;">
                    <template v-for="(ss, idx) in medias">
                      <Option :value="ss.id" :key="idx">{{ss.name}}</Option>
                    </template>
                  </Select>
                </div>
                <div class="cons-subs" v-else-if="[304].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'volume'">
                  音量：<Slider v-model="item.args.volume" :min="0" :max="100" style="width: 400px;"></Slider>
                </div>
                <div class="cons-subs" v-else-if="[304].indexOf(selectedParamType(item.paramId)) != -1 && item.code == 'play'">
                  <Select v-model="item.args.mediaId" style="width: 200px;flex:none;margin-right:10px;">
                    <template v-for="(ss, idx) in medias">
                      <Option v-if="ss.type == 2" :value="ss.id" :key="idx">{{ss.name}}</Option>
                    </template>
                  </Select>
                </div>
                <div class="cons-subs" v-else>
                  无
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div slot="footer">
      <!-- <Button type="success" @click="addConBig(form.cons)" style="margin-right:10px;">新增条件</Button> -->
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalUnionEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    medias: {
      type: Array,
      default(){ return [];}
    },
    codes: {
      type: Array,
      default(){ return [];}
    },
    actions: {
      type: Array,
      default(){ return [];}
    },
    stations: {
      type: Array,
      default(){ return [];}
    },
    devices: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      tabName: 'cfg',
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      form: {
        id: 0,
        name: '',
        description: '',
        weeks: [1,2,3,4,5,6,7],
        start1: '00:00',
        end1: '00:00',
        start2: '00:00',
        end2: '00:00',
        start3: '00:00',
        end3: '00:00',
        start4: '00:00',
        end4: '00:00',
        cons: [
          {
            name: '',
            description: '',
            stationId: 0,
            paramId: 0,
            items: [
              {
                code: '',
                op: '',
                val: '',
                keep: 10,
              }
            ],
          }
        ],
        acts: [
          {
            name: '',
            description: '',
            stationId: 0,
            paramId: 0,
            code: '',
            args: {},
          }
        ],
      },
      rules: {
        name: { required: true, type: 'string', max: 30, message: '名称不能为空且长度必须少于30个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        this.tabName = 'cfg';
        // console.log('dlg open', this.item, this.selectedDevices)
        if(!this.isAdd){
          this.form.id = this.item.data.config.id;
          this.form.name = this.item.data.config.name;
          this.form.description = this.item.data.config.description;
          this.form.weeks = this.item.data.config.weeks;
          this.form.start1 = new Date(this.item.data.config.start1).format('HH:mm');
          this.form.end1 = new Date(this.item.data.config.end1).format('HH:mm');
          this.form.start2 = new Date(this.item.data.config.start2).format('HH:mm');
          this.form.end2 = new Date(this.item.data.config.end2).format('HH:mm');
          this.form.start3 = new Date(this.item.data.config.start3).format('HH:mm');
          this.form.end3 = new Date(this.item.data.config.end3).format('HH:mm');
          this.form.start4 = new Date(this.item.data.config.start4).format('HH:mm');
          this.form.end4 = new Date(this.item.data.config.end4).format('HH:mm');
          this.form.cons = this.item.data.conditions;
          this.form.acts = this.item.data.actions;
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.description = '';
          this.form.weeks = [1,2,3,4,5,6,7];
          this.form.start1 = '00:00';
          this.form.end1 = '00:00';
          this.form.start2 = '00:00';
          this.form.end2 = '00:00';
          this.form.start3 = '00:00';
          this.form.end3 = '00:00';
          this.form.start4 = '00:00';
          this.form.end4 = '00:00';
          this.form.cons = [
            {
              name: '',
              description: '',
              stationId: 0,
              paramId: 0,
              items: [
                {
                  code: '',
                  op: '',
                  val: '',
                  keep: 10,
                }
              ],
            }
          ];
          this.form.acts = [
            {
              name: '',
              description: '',
              stationId: 0,
              paramId: 0,
              code: '',
              args: {},
            }
          ];
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    'item.paramId'(){
      this.item.code = '';
    },
  },
  computed: {
    ...mapState('auth', ['user', 'config']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['poleParamTypeName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'poleType', 'mapCenter', 'weeks']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
    selectedStations: function(){
      return this.selectedDevices;
    },
    selectedParams: function(){
      return function(stationId){
        let ss = this.devices.filter(p => p.stationId == stationId);
        if(ss.length == 0)return [];
        return ss;
      }
    },
    selectedParamType: function(){
      return function(paramId){
        let ss = this.devices.filter(p => p.id == paramId);
        if(ss.length == 0)return 0;
        return ss[0].type;
      }
    },
    currentCodes: function(){
      return function(paramType){
        return this.codes.filter(p => p.paramType == paramType);
      }
    },
    currentActions: function(){
      return function(paramType){
        return this.actions.filter(p => p.paramType == paramType);
      }
    },
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    addActBig: function(items){
      items.push({
        name: '',
        description: '',
        stationId: 0,
        paramId: 0,
        code: '',
        args: {},
      });
    },
    addConBig: function(items){
      items.push({
        name: '',
        description: '',
        stationId: 0,
        paramId: 0,
        items: [
          {
            code: '',
            op: '',
            val: '',
            keep: 10,
          }
        ],
      });
    },
    addCon: function(items){
      items.push({code: '', op: '', val: '', keep: 0});
    },
    removeCon: function(items, idx){
      items.splice(idx, 1);
    },
    conStationIdChanged: function(item){
      item.paramId = 0;
    },
    conParamIdChanged: function(item){
      // console.log('con param id changed', item)
      item.items.splice(0, item.items.length, {code: '', op: '', val: '', keep: 0});
      // item.items.push({code: '', op: '', val: '', keep: 0});
    },
    actParamIdChanged: function(item){
      // console.log('con param id changed', item)
      item.code = '';
      // item.items.splice(0, item.items.length, {code: '', op: '', val: '', keep: 0});
      // item.items.push({code: '', op: '', val: '', keep: 0});
    },
    switchTab: function(tab){
      this.tabName = tab;
    },
    ok: async function () {
      // let result = await this.$refs.form.validate();
      // if(!result){
      //   return;
      // }
      // if(this.form.stationId == 0){
      //   this.$Message.warning('请选择一个灯杆');
      //   return;
      // }
      this.form.name = this.form.name.trim();
      if(this.form.name.length == 0 || this.form.name.length > 30){
        this.$Message.warning('请输入规则名称');
        return;
      }
      if(this.form.cons.length == 0){
        this.$Message.warning('请设置触发条件');
        return;
      }
      if(this.form.acts.length == 0){
        this.$Message.warning('请设置触发动作');
        return;
      }
      let index = 1;
      for(let con of this.form.cons){
        con.name = con.name.trim();
        if(con.name.length == 0 || con.name.length > 30){
          this.$Message.warning(`请输入条件${index}的条件名称`);
          return;
        }
        if(con.stationId <= 0){
          this.$Message.warning(`请选择条件${index}的所属站点`);
          return;
        }
        if(con.paramId <= 0){
          this.$Message.warning(`请选择条件${index}的所属站点属性`);
          return;
        }
        if(con.items.length == 0){
          this.$Message.warning(`请设置条件${index}的条件内容`);
          return;
        }
        let sidx = 1;
        for(let item of con.items){
          if(item.code == '' || !item.code){
            this.$Message.warning(`请选择条件${index}的子条件${sidx}参数`);
            return;
          }
          if(item.op == '' || !item.op){
            this.$Message.warning(`请选择条件${index}的子条件${sidx}对比操作`);
            return;
          }
          if(item.val == '' || !item.val){
            this.$Message.warning(`请选择条件${index}的子条件${sidx}对比值`);
            return;
          }
          sidx++;
        }
        index++;
      }
      index = 1;
      for(let act of this.form.acts){
        act.name = act.name.trim();
        if(act.name.length == 0 || act.name.length > 30){
          this.$Message.warning(`请输入触发动作${index}的动作名称`);
          return;
        }
        if(act.stationId <= 0){
          this.$Message.warning(`请选择触发动作${index}的所属站点`);
          return;
        }
        if(act.paramId <= 0){
          this.$Message.warning(`请选择触发动作${index}的所属站点属性`);
          return;
        }
        if(act.code == '' || !act.code){
          this.$Message.warning(`请选择触发动作${index}的指令类型`);
          return;
        }
        index++;
      }
      // console.log('save form data', this.form)
      // this.form.content.install = new Date(this.form.content.install).format('yyyy-MM-dd');
      let req;
      if(this.form.id <= 0){
        req = this.$axios.post(`//${this.domains.trans}/union/config/list`, this.form);
      }else{
        req = this.$axios.put(`//${this.domains.trans}/union/config/list/${this.form.id}`, this.form)
      }
      req.then(res => {
        if(res.code == 0){
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }else{
          // this.$Message.error(res.data);
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.tabs-control{
  /* border: solid 1px red; */
  height: 600px;
  display: flex;
  flex-direction: column;
}
.tabs-header{
  /* border: solid 1px red; */
  height: 30px;
  flex: none;
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
  margin-bottom: 15px;
}
.tab-item{
  /* border: solid 1px red; */
  padding: 0 10px;
  cursor: pointer;
  border-bottom: solid 1px white;
}
.tab-active{
  /* background-color: rgba(0, 0, 255, 0.411);
  color: white; */
  border-bottom: solid 1px blue;
}
.tabs-content{
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
  overflow: auto;
}
.con-item{
  /* border: solid 1px red; */
  margin-bottom: 10px;
}
.con-item table {
  width: 100%;
  /* height: 93px; */
}
.con-item table td {
  /* width: 115px; */
  /* text-align: center; */
  box-sizing: border-box;
  border: 1px solid #d0d9e9;
  /* border-bottom: 1px solid #d0d9e9; */
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  padding: 2px 3px;
}
.cons-subs{
  display: flex;
  align-items: center;
}
</style>