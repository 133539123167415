<template>
  <div class="device-item-content">
    <template v-for="(item,index) in devices">
      <div class="device-info" :key="index" ref="infoHight">
        <div class="device-info-img">
          <img :src="getImage(item)" alt="">
        </div>
        <div class="device-info-box">
          <div class="device-info-list">
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.asset.index.name" alt="">
              </div>
              <div class="device-info-con device-info-conBig">
                <div class="device-info-val">{{item.name}}</div>
                <div class="device-info-title">设备名称</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.pole" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val"> {{item.stationName}}</div>
                <div class="device-info-title">所属灯杆</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.type" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.connectTypeName}}</div>
                <div class="device-info-title">设备类型</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.date" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.buildDate}}</div>
                <div class="device-info-title">安装日期</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.frequency" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.system && item.system.cpu || '-'}}, 使用率:{{item.system && item.system.cpu_use}}%</div>
                <div class="device-info-title">主频</div>
              </div>
            </div>
          </div>
          <div class="device-info-list">
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.icons.wifi.storage" alt="">
              </div>
              <div class="device-info-con device-info-conBig">
                <div class="device-info-val">闪存{{item.system && item.system.flash || '-'}}M, 内存{{item.system && item.system.ram || '-'}}M, 使用率:{{item.system && item.system.memory_use || '-'}}%</div>
                <div class="device-info-title">存储</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.RunTime" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{getRunTime(item.system && item.system.runtime)}}</div>
                <div class="device-info-title">运行时间</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.broadcast" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.system && item.wifi.total || '-'}}信道：[{{getChannels(item.wifi)}}]</div>
                <div class="device-info-title">无线广播数</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.user" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{getMaxUserCount(item.wifi)}}</div>
                <div class="device-info-title">最大用户数</div>
              </div>
            </div>
          </div>
          <div class="device-info-list">
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.icons.wifi.ssid" alt="">
              </div>
              <div class="device-info-con device-info-conBig">
                <div class="device-info-val">{{getWifiVaps(item.wifi)}}</div>
                <div class="device-info-title">ssid列表</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.activeUser" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val"> {{item.system && item.user.total || '-'}}, 共 {{getConnCnt(item.user)}} 连接</div>
                <div class="device-info-title">当前用户数</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.icons.wifi.flow" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{getUpload(item.user)}}</div>
                <div class="device-info-title">上传流量</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
export default {
  name: 'deviceList',
  components: {
    recordPage
  },
  props: {
    devices: {
      type: Array,
      default() { return [] },
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      devId: -1,
      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      chartHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('common', ['getConnectTypeName']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
    },
  },
  mounted: function () {
    let now = new Date();//getDate
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
  },
  methods: {
    getConnCnt: function (user) {
      if (!user || !user.list) return '-';
      let cnt = 0;
      user.list.map(p => {
        cnt += parseInt(p.conn);
      });
      return cnt;
    },
    getUpload: function (user) {
      if (!user || !user.list) return '-';
      let pkgs = 0, bytes = 0;
      user.list.map(p => {
        pkgs += parseInt(p.up_pkts);
        bytes += parseInt(p.up_bytes);
      });
      if (bytes > this.gb) {
        return `${pkgs}包, 共${(bytes / this.gb).toFixed(2)}Gb`;
      } else if (bytes > this.mb) {
        return `${pkgs}包, 共${(bytes / this.mb).toFixed(2)}Mb`;
      } else if (bytes > this.kb) {
        return `${pkgs}包, 共${(bytes / this.kb).toFixed(2)}Kb`;
      } else {
        return `${pkgs}包, 共${(bytes)}字节`;
      }
    },
    getWifiVaps: function (wifi) {
      if (!wifi || !wifi.list) return '-';
      let chs = [];
      wifi.list.map(p => {
        p.vap.map(q => {
          if (q.enable == '1') {
            let ssid = `${q.ssid}:${q.password}`;
            if (chs.indexOf(ssid) == -1) {
              chs.push(ssid);
            }
          }
        });
      });
      return chs.join(',');
    },
    getMaxUserCount: function (wifi) {
      if (!wifi || !wifi.list) return '-';
      let cnt = 0;
      wifi.list.map(p => {
        cnt += parseInt(p.max_sta);
      });
      return cnt;
    },
    getChannels: function (wifi) {
      if (!wifi || !wifi.list) return '-';
      let chs = [];
      wifi.list.map(p => {
        chs.push(p.channel);
      });
      return chs.join(',');
    },
    getRunTime: function (secs) {
      if (secs == undefined) { return `-小时-分-秒`; }
      let hour = Math.floor(secs / 3600);
      let remain = secs - hour * 3600;
      let min = Math.floor(remain / 60);
      remain -= min * 60;
      return `${hour}小时${min}分${remain}秒`;
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.wifi.offline3;
      return this.img.pole.icons.wifi.online3;
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 30px;
  margin-top: 15px;
}
.device-info-img img {
  margin-top: 35px;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con.device-info-conBig {
  width: 270px;
}
.device-info-con {
  width: 160px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.device-info-list {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
</style>
