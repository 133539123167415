<template>
  <Modal v-model="showModal" :mask-closable="false" width="1500px">
    <div slot="header">{{showLang('com.edit.lamp.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.device.pole.name')" style="width: 400px; margin-right: 38px;"></Input>
          {{showLang('com.state.type')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.type" style="width: 200px; margin: 0 10px;">
            <template v-for="(v, k) in newLightTypes">
              <Option :value="parseInt(k)" :key="k">{{v}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="stationId" :label="showLang('com.device.pole.association')" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.stationId" @on-change="stationChanged" :disabled="!isAdd" style="width: 200px; margin-right: 10px;">
            <Option v-for="(s, idx) in stations" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
          {{showLang('com.export.cn.group.relate')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.area" style="width: 123px; margin: 0 10px;">
            <template v-for="(s, idx) in areas">
              <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
            </template>
          </Select>
            <!-- {{showLang('com.device.pole.association')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.pole" style="width: 200px; margin: 0 10px;">
            <template v-for="(s, idx) in poles">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select> -->
        </div>
      </FormItem>
      <FormItem prop="connectType" :label="showLang('com.export.cn.association')" style="margin-right: 15px">
        <div class="flex-row">
          <Select :placeholder="showLang('save.select')" v-model="form.deviceId" style="width: 400px; margin-right: 25px;" @on-change="getUseablePlan">
            <Option v-for="(dev, didx) in devices" :key="didx" :value="dev.id">{{getDeviceName(dev)}}</Option>
          </Select>
          {{showLang('com.ins.timeTable')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.timeId" style="width: 200px; margin: 0 10px;">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <template v-for="(s, idx) in timeTables">
              <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="tr" :label="showLang('com.lighting.T.monitor')" style="margin-right: 15px">
        <Input type="number" v-model="form.content.tr" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.data.threshold')}}</span><span
          slot="append">℃</span></Input>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.lighting.V.monitor')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ur" style="width: 200px; margin-right: 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span
            slot="append">V</span></Input>
          <Input type="number" v-model="form.content.ul" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">%</span></Input>
          <Input type="number" v-model="form.content.uh" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">%</span></Input>
        </div>
      </FormItem>
       <FormItem prop="enp"  style="margin-right: 15px">
        <div class="flex-row">
         {{showLang('com.lamp.power.alarm.time')}}
         <Time-picker format="HH:mm" :placeholder="showLang('com.date.start')" v-model="form.content.ats" style="width: 110px;margin-left:15px;"></Time-picker>
         <Time-picker format="HH:mm" :placeholder="showLang('com.date.end')" v-model="form.content.ate" style="width: 110px;margin-left:15px;"></Time-picker>
        </div>
      </FormItem>

      <FormItem prop="enp" :label="showLang('com.ope.way.channel.1')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en1" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Select :placeholder="showLang('save.select')" v-model="form.content.nm1" style="margin-right:10px;width:70px">
            <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
            <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
          </Select>
          <Input type="number" v-model="form.content.pr1" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.pl1" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.ph1" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">W</span></Input>
          <Checkbox v-model="form.content.pa1">{{showLang('com.state.is.police')}}</Checkbox>
          <Input v-model="form.content.tp1" style="width: 170px; margin-right:10px;"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
          <Input type="number" v-model="form.content.lp1" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc1" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.ope.way.channel.2')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en2" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Select :placeholder="showLang('save.select')" v-model="form.content.nm2" style="margin-right:10px;width:70px">
            <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
            <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
          </Select>
          <Input type="number" v-model="form.content.pr2" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.pl2" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.ph2" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">W</span></Input>
           <Checkbox v-model="form.content.pa2">{{showLang('com.state.is.police')}}</Checkbox>
          <Input v-model="form.content.tp2" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
          <Input type="number" v-model="form.content.lp2" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc2" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.ope.way.channel.3')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en3" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Select :placeholder="showLang('save.select')" v-model="form.content.nm3" style="margin-right:10px;width:70px">
            <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
            <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
          </Select>
          <Input type="number" v-model="form.content.pr3" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.pl3" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.ph3" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">W</span></Input>
           <Checkbox v-model="form.content.pa3">{{showLang('com.state.is.police')}}</Checkbox>
          <Input v-model="form.content.tp3" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
          <Input type="number" v-model="form.content.lp3" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc3" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.ope.way.channel.4')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en4" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Select :placeholder="showLang('save.select')" v-model="form.content.nm4" style="margin-right:10px;width:70px">
            <Option :value="showLang('com.lamp.host')">{{showLang('com.lamp.host')}}</Option>
            <Option :value="showLang('com.lamp.assist')">{{showLang('com.lamp.assist')}}</Option>
          </Select>
          <Input type="number" v-model="form.content.pr4" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.pl4" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.ph4" style="width: 150px; margin-right:10px"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span
            slot="append">W</span></Input>
          <Checkbox v-model="form.content.pa4">{{showLang('com.state.is.police')}}</Checkbox>
          <Input v-model="form.content.tp4" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.type')}}</span></Input>
          <Input type="number" v-model="form.content.lp4" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span
            slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc4" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.lighting.leakage')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ll" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.stat.level1')}}</span><span
            slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.lh" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.stat.level2')}}</span><span
            slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.lx" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.stat.level3')}}</span><span
            slot="append">mA</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" :label="showLang('com.ins.flood.monitoring')" style="margin-right: 15px">
        <div class="flex-row">
          {{showLang('com.stat.level1')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l1" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          {{showLang('com.stat.level2')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l2" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          {{showLang('com.stat.level3')}}{{showLang('com.ins.ch.order')}}
          <Select :placeholder="showLang('save.select')" v-model="form.content.l3" style="margin: 0 10px;width:100px">
            <Option :value="0">{{showLang('com.state.not')}}</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
    areas: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      accounts: [],
      poles: [],
      devices: [],
      timeTables: [],
      form: {
        id: 0,
        stationId: 0,
        connectType: 0, //设备接入方式，0为设备直连，其他都是从三方平台或其他SDK接入
        deviceId: 0,
        code: '',
        name: '',
        type: 199,
        content: {
          //基本配置相关
          type: 101, //单灯类型
          timeId: 0, //时间表ID
          area: 0, //单独所属控制区域
          pole: 0, //关联灯杆
          stid: 0, //设备小类
          ur: 220, //额定电压
          uh: 120, //电压阈值上限
          ul: 80, //电压阈值下限
          tr: 70, //温度阈值
          ll: 100, //一级漏电电流阈值
          lh: 1000, //一级漏电电流阈值
          lx: 2000, //一级漏电电流阈值
          l1: 0, //一级水浸通道号
          l2: 0, //一级水浸通道号
          l3: 0, //一级水浸通道号
          ats:"00:00",//报警时段开始时间
          ate:"00:00",//报警时段结束时间
          //1路
          en1: true, //控制使能
          nm1: '主灯', //名称
          gp1: 0, //分组号
          pr1: 100, //额定功率
          tp1: '默认', //灯具类型
          lp1: 100, //灯具功率
          lc1: 1, //灯具数量
          pa1:false,//是否启用功率报警
          pl1:80,//功率下限值
          ph1:120,//功率上限值
          //2路
          en2: false, //控制使能
          nm2: '辅灯', //名称
          gp2: 0, //分组号
          pr2: 100, //额定功率
          tp2: '默认', //灯具类型
          lp2: 100, //灯具功率
          lc2: 1, //灯具数量
          pa2:false,//是否启用功率报警
          pl2:80,//功率下限值
          ph2:120,//功率上限值
          //3路
          en3: false, //控制使能
          nm3: '辅灯', //名称
          gp3: 0, //分组号
          pr3: 100, //额定功率
          tp3: '默认', //灯具类型
          lp3: 100, //灯具功率
          lc3: 1, //灯具数量
           pa3:false,//是否启用功率报警
          pl3:80,//功率下限值
          ph3:120,//功率上限值
          //4路
          en4: false, //控制使能
          nm4: '辅灯', //名称
          gp4: 0, //分组号
          pr4: 100, //额定功率
          tp4: '默认', //灯具类型
          lp4: 100, //灯具功率
          lc4: 1, //灯具数量
           pa4:false,//是否启用功率报警
          pl4:80,//功率下限值
          ph4:120,//功率上限值
        }
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        console.log('modal level edit mounted', this.item, this.stations)
        this.isAdd = this.item.isAdd;
        this.getUseablePlan();
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          // this.form.stationId = this.item.data.stationId;
          this.form.stationId = this.item.data.content.pole;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          this.form.connectType = 0;

          this.stationChanged(() => {
            this.form.deviceId = this.item.data.deviceId;
            for (let k in this.form.content) {
              this.form.content[k] = this.item.data.content[k];
            }
          });
        } else {
          this.form.id = 0;
          this.form.stationId = this.stations.length > 0 ? this.stations[0].id : 0;
          this.form.deviceId = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.connectType = 0;
          this.stationChanged(() => {
            // this.form.deviceId = this.item.data.deviceId;
            // for(let k in this.form.content){
            //   this.form.content[k] = this.item.data.content[k];
            // }
          });

          // this.form.type = 101;
          this.form.content = {
            //基本配置相关
            type: 101,
            timeId: 0, //时间表ID
            area: 0, //单独所属控制区域
            pole: 0,
            stid: 0,
            ur: 220, //额定电压
            uh: 120, //电压阈值上限
            ul: 80, //电压阈值下限
            tr: 70, //温度阈值
            ll: 100, //一级漏电电流阈值
            lh: 1000, //一级漏电电流阈值
            lx: 2000, //一级漏电电流阈值
            l1: 0, //一级水浸通道号
            l2: 0, //一级水浸通道号
            l3: 0, //一级水浸通道号
            ats:"00:00",//报警时段开始时间
            ate:"00:00",//报警时段结束时间
            //1路
            en1: true, //控制使能
            nm1: this.showLang('com.lamp.host'), //名称
            gp1: 0, //分组号
            pr1: 100, //额定功率
            tp1: this.showLang('com.state.default'), //灯具类型
            lp1: 100, //灯具功率
            lc1: 1, //灯具数量
            pa1:false,//是否启用功率报警
            pl1:80,//功率下限值
            ph1:120,//功率上限值
            //2路
            en2: false, //控制使能
            nm2: this.showLang('com.lamp.assist'), //名称
            gp2: 0, //分组号
            pr2: 100, //额定功率
            tp2: this.showLang('com.state.default'), //灯具类型
            lp2: 100, //灯具功率
            lc2: 1, //灯具数量
            pa2:false,//是否启用功率报警
            pl2:80,//功率下限值
            ph2:120,//功率上限值
            //3路
            en3: false, //控制使能
            nm3: this.showLang('com.lamp.assist'), //名称
            gp3: 0, //分组号
            pr3: 100, //额定功率
            tp3: this.showLang('com.state.default'), //灯具类型
            lp3: 100, //灯具功率
            lc3: 1, //灯具数量
            pa3:false,//是否启用功率报警
            pl3:80,//功率下限值
            ph3:120,//功率上限值
            //4路
            en4: false, //控制使能
            nm4: this.showLang('com.lamp.assist'), //名称
            gp4: 0, //分组号
            pr4: 100, //额定功率
            tp4: this.showLang('com.state.default'), //灯具类型
            lp4: 100, //灯具功率
            lc4: 1, //灯具数量
            pa4:false,//是否启用功率报警
            pl4:80,//功率下限值
            ph4:120,//功率上限值
          }
        }
        // console.log('modal level edit mounted', this.form.content, this.item.data.content)
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes', 'connectTypes']),
    ...mapGetters('auth', ['showLang']),
    boxType: function () {
      return function (deviceId) {
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function () {
    this.form.content.nm1=this.showLang('com.lamp.host');
    this.form.content.nm2=this.showLang('com.lamp.assist');
    this.form.content.nm3=this.showLang('com.lamp.assist');
    this.form.content.nm4=this.showLang('com.lamp.assist');
    this.form.content.tp1=this.showLang('com.state.default');
    this.form.content.tp2=this.showLang('com.state.default');
    this.form.content.tp3=this.showLang('com.state.default');
    this.form.content.tp4=this.showLang('com.state.default');
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    getDeviceName: function (item) {
      if (this.form.connectType == 0) {
        return `${item.name}[${this.deviceTypes[item.type]}][ID:${item.code}]`
      } else {
        return item.name;
      }
    },
    getAccounts: async function () {
      // this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/station/account/GetAccounts`, { type: 4 });
      // this.loading = false;
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "devices", res.data);
      this.form.deviceId = 0;
    },
    getDevices: async function () {
      // this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/station/config/GetStationDevices`, { groupId: 0, stationId: 0, type: 3 });
      // this.loading = false;
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "devices", res.data);
      // this.form.deviceId = 0;
    },
    connectTypeChanged: function () {
      if (this.form.connectType == 0) {
        this.getDevices();
      } else {
        this.getAccounts();
      }
    },
    stationChanged: function (callback) {
      if (this.form.stationId <= 0) {
        this.$set(this, 'devices', []);
        this.form.content.area = 0;
        this.form.deviceId = 0;
      } else {
        this.getDevices();
        this.$axios.post(`//${this.domains.trans}/station/config/QueryLampPole`, { groupId: 0, stationId: this.form.stationId }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
          } else {
            this.$set(this, 'poles', res.data);
            this.form.content.pole = 0;
            if (callback && `${typeof callback}` == 'function') callback();
          }
        });
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.stationId <= 0) {
        this.$Message.warning(this.showLang('com.save.err.station'));
        return;
      }
      if (this.form.content.area <= 0) {
        this.$Message.warning(this.showLang('select.group'));
        return;
      }
      if (this.form.deviceId <= 0) {
        this.$Message.warning(this.showLang('save.light.device.need'));
        return;
      }
      if (this.form.content.timeId <= 0) {
        this.$Message.warning(this.showLang('save.light.plan.need'));
        return;
      }
      if (this.form.content.tr < 30 || this.form.tr > 200) {
        this.$Message.warning(this.showLang('save.light.range.temp',30,70));
        return;
      }
      if (this.form.content.ur < 0 || this.form.ur > 500) {
        this.$Message.warning(this.showLang('save.light.range.voltage',0,500));
        return;
      }
      if (this.form.content.ll <= 0) {
        this.$Message.warning(this.showLang('save.light.range.leakage1',0));
        return;
      }
      if (this.form.content.lh < this.form.content.ll) {
        this.$Message.warning(this.showLang('save.light.range.leakage2'));
        return;
      }
      if (this.form.content.lx < this.form.content.lh) {
        this.$Message.warning(this.showLang('save.light.range.leakage3'));
        return;
      }

      this.$axios.post(`//${this.domains.trans}/station/config/SavePoleLight`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>