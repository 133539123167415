<template>
  <Modal v-model="showModal" :mask-closable="false" :width="['playMedia'].indexOf(command.code) != -1 ? 1070 : 550">
    <div slot="header">控制参数</div>
    <Form :label-width="80">
      <FormItem label="选择节目" v-if="['playMedia'].indexOf(command.code) != -1">
      <div class="modal-area">
        <div class="modal-tree-area">
          <Tree ref="tree" :data="treeData" @on-select-change="nodeChange"></Tree>
        </div>
        <div class="modal-program-area">
          <div class="media-item media-header">待选媒体列表</div>
          <div class="media-item" v-for="(item, idx) in medias" :key="idx">
            <div class="media-item-title">
              [{{mediaTypes[item.type]}}]{{item.name}}
            </div>
            <div class="media-item-btn" @click="previewItem(item)"> &gt;&gt; </div>
          </div>
        </div>
        <div class="modal-preview-area">
          <video ref="videoPlayer" v-if="previewMedia.type == 1 && previewUrl != ''" :src="previewUrl" controls="controls" @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
          <audio ref="audioPlayer" v-else-if="previewMedia.type == 2 && previewUrl != ''" :src="previewUrl" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
          <img v-else-if="previewMedia.type == 3 && previewUrl != ''" :src="previewUrl" class="media-player" @load="imageLoaded" />
          <span v-else class="media-player">当前无预览节目</span>
        </div>
      </div>
      </FormItem>
      <FormItem label="选择任务" v-if="['playTask'].indexOf(command.code) != -1">
        <Select v-model="form.taskIds" multiple style="width: 400px;">
          <template v-for="item in tasks">
            <Option :key="item.id" :value="item.id">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="亮度" v-if="['setBright'].indexOf(command.code) != -1">
        <!-- <Input v-model="form.bright" type="number" style="width: 120px;height:32px;"></Input> -->
        <Slider v-model="form.bright" :min="0" :max="100" show-input style="width: 400px;"></Slider>
      </FormItem>
      <FormItem label="音量" v-if="['setVolume'].indexOf(command.code) != -1">
        <!-- <Input v-model="form.bright" type="number" style="width: 120px;height:32px;"></Input> -->
        <Slider v-model="form.volume" :min="0" :max="100" show-input style="width: 400px;"></Slider>
      </FormItem>
      <FormItem label="确认密码" v-if="command.needPswd">
        <Input v-model="form.pswd" type="password" style="width: 120px;height:32px;"></Input><span style="color:red;margin-left:20px">*** 此操作需要确认当前用户密码</span>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button v-if="['openControl'].indexOf(command.code) == -1" style="margin-right: 8px" type="primary" @click="ok">发送</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalCommandArgs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default() { return {}; }
    }
  },
  data() {
    return {
      showModal: this.value,
      date: "",
      modalSetForm: false,
      userPassword: "",
      loading: false,
      isSwitch: true,
      groups: [],
      tasks: [],
      selectedGroup: {},
      medias: [],
      treeData: [],
      previewMedia: {type: 0},
      view:{
        type: 0,
        url: '',
      },
      form: {
        pswd: '',
        bright: 255,
        volume: 100,
        mediaId: 0,
        taskIds: 0,
      },
    }
  },
  computed: {
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'mediaTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
  },
  mounted: function () {
    // let d = new Date();
    // d.setHours(d.getHours() + 1);
    // this.date = d.format('yyyy-MM-dd  HH:mm:ss');
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        if(this.command.code == 'playMedia'){
          this.getGroups();
        }else if(this.command.code == 'playTask'){
          this.getTasks();
        }
        this.$set(this.form, 'channels', []);
        let now = new Date();
        this.form.date = now.format('yyyy-MM-dd');
        now.setHours(now.getHours() + 1);
        this.form.expire = now.format('yyyy-MM-dd HH:mm:ss');
        this.pswd = '';
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  methods: {
    getTasks: function(){
      this.$axios.get(`//${this.domains.trans}/media/task`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        // console.log('get tasks', res.data);
        this.$set(this, 'tasks', res.data.filter(p => !p.needView));
      });
    },
    audioLoaded: function(e){
      e.srcElement.play();
    },
    imageLoaded: function(){
    },
    videoLoaded: function(v){
      v.srcElement.play();
    },
    setViewByTaskCfg: function(){
      if(!this.item || !this.item.extraData || !this.item.extraData.taskCfg || !this.item.extraData.taskCfg.task || !this.item.extraData.taskCfg.task.items || this.item.extraData.taskCfg.task.items.length == 0){
        this.view.type = 0;
        this.view.url = '';
      }else{
        let item = this.item.extraData.taskCfg.task.items[0];
        this.view.type = item._program.layers[0].sources[0]._type;
        this.view.url = item._program.layers[0].sources[0].url;
      }
    },
    previewItem: function(item){
      // console.log('preview Item', item)
      this.form.mediaId = item.id;
      this.previewMedia = item;
      this.previewUrl = `http://${this.domains.trans}/station/file/media?id=${item.fileId}&auth=${this.token}`
    },
    getGroups: function(){
      this.$axios.get(`//${this.domains.trans}/media/group`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'groups', res.data);
        let tree = [];
        this.initTreeData(tree, res.data, 0);
        this.$set(this, 'treeData', tree);
        if(tree.length > 0){
          this.nodeChange(tree, tree[0])
        }
      });
    },
    initTreeData: function(children, list, parentId){
      let ps = list.filter(p => p.parentId == parentId);
      for(let p of ps){
        let node = {id: p.id, title: p.name, data: p, children: [], expand: true}
        this.initTreeData(node.children, list, node.id);
        children.push(node);
      }
    },
    nodeChange: function(nodes, node){
      // console.log('node change', node);
      if(this.selectedGroup.id == node.id)return;
      this.selectedGroup = node;
      this.getMedias();
    },
    getMedias: function(){
      let groupId = 0;
      if(this.selectedGroup.id > 0){
        groupId = this.selectedGroup.id;
      }
      if(groupId == 0){
        this.$set(this, 'medias', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/media/operation/GetConfirm`, {groupId, type: 2}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    ok: function () {
      if (this.command.needPswd) {
        if (this.form.pswd == '') {
          this.$Message.warning('请输入密码');
          return;
        }
        this.$store.dispatch('auth/checkPswd', { pswd: this.form.pswd }).then(res => {
          if (res.code != 0) {
            // this.$Message.error('密码错误');
            return;
          } else {
            this.createCmd();
            if(this.$refs.videoPlayer)this.$refs.videoPlayer.pause();
            if(this.$refs.audioPlayer)this.$refs.audioPlayer.pause();
          }
        });
      } else {
        this.createCmd();
        if(this.$refs.videoPlayer)this.$refs.videoPlayer.pause();
        if(this.$refs.audioPlayer)this.$refs.audioPlayer.pause();
      }
    },
    createCmd: function () {
      let args;
      if (this.command.code == 'openDevice') {
        args = {_type: "SwitchScreen", turnOn: true};
      } else if (this.command.code == 'closeDevice') {
        args = {_type: "SwitchScreen", turnOn: false};
      } else if (this.command.code == 'setBright') {
        args = {_type: "SetBrightness", brightness: this.form.bright};
      } else if (this.command.code == 'setVolume') {
        args = {_type: "SetVolume", volume: this.form.volume};
      } else if (this.command.code == 'playMedia') {
        args = {_type: "playMedia", mediaId: this.form.mediaId};
      } else if (this.command.code == 'playTask') {
        args = {_type: "playTask", taskIds: this.form.taskIds};
      } else {
        args = {};
      }
      this.$emit('saved', { cmd: this.command, args });
      this.showModal = false;
      this.form.pswd = '';
    },
    cancel() {
      this.showModal = false;
      if(this.$refs.videoPlayer)this.$refs.videoPlayer.pause();
      if(this.$refs.audioPlayer)this.$refs.audioPlayer.pause();
    },
  }
}
</script>
<style scoped>
.media-item{
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  height: 30px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.media-header{
  background-color: rgba(7, 7, 241, 0.342);
  color: white;
  font-size: 20px;
}
.media-item:hover{
  background-color: rgba(109, 161, 161, 0.247);
}
.media-item-title{
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  width: 100px;
  flex: auto;
}
.media-item-btn{
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  border-radius: 4px;
  width: 25px;
  flex: none;
  color: blue;
  text-align: center;
  margin: 3px;
}
.modal-area{
  /* border: solid 1px red; */
  display: flex;
  height: 500px;
}
.modal-tree-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 250px;
  flex: none;
  margin-right: 10px;
  overflow-y: auto;
}
.modal-program-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 350px;
  flex: none;
  margin-right: 10px;
  padding: 5px;
  overflow-y: auto;
}
.modal-preview-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.media-player{
  width: 100%;
  max-height:100%;
  border-radius: 4px;
  text-align: center;
}
</style>