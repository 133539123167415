<template>
<div class="config-station-container">
  <!-- <div class="station-search-area">
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
  </div> -->
  <div class="station-data-area" ref="table">
    <u-table :data="items" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="name" label="名称" sortable>
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="rootUrl" label="服务器IP" sortable>
        <template slot-scope="params">
          {{params.row.rootUrl}}
        </template>
      </u-table-column>
      <u-table-column prop="port" label="服务器端口" sortable>
        <template slot-scope="params">
          {{params.row.port}}
        </template>
      </u-table-column>
      <u-table-column prop="appKey" label="用户名" sortable>
        <template slot-scope="params">
          {{params.row.appKey}}
        </template>
      </u-table-column>
      <u-table-column prop="appSecret" label="用户密码" sortable>
        <template slot-scope="params">
          {{params.row.appSecret}}
        </template>
      </u-table-column>
      <u-table-column width="120">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='asra' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
          <AuthButton opCode='asrv' style="" @click="getFuncs">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='asre' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='asrd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalAccountEdit v-model="showEditModal" :item="editItem" @saved="dataSaved" />
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalAccountEdit from './ModalAccountEdit'
export default {
  name: 'YsAccountIndex',
  components:{
    ModalAccountEdit,
  },
  props: {
  },
  data () {
    return {
      tabHeight: 300,
      loading: false,
      showEditModal: false,
      count: 0,
      items: [],
      editItem: {isAdd: false, index: -1, data: {}},
      filter: ''
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 50);
    this.getFuncs();
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd HH:mm:ss');
    },
    reload: function(){
      this.getFuncs();
    },
    getFuncs: async function(){
      this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/station/account/GetAccounts`, {type: 5});
      this.loading = false;
      if(res.code !== 0){
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "items", res.data);
    },
    handleAdd: function(){
      this.editItem.isAdd = true;
      this.editItem.index = -1;
      this.editItem.data = {};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem.isAdd = false;
      this.editItem.index = params.index;
      this.editItem.data = params.row;
      this.showEditModal = true;
    },
    dataSaved: function(){
      this.getFuncs();
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除操作[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/account/DeleteAccount`, {id: params.row.id});
          if(res.code !== 0){
            // this.$Message.error(res.data);
            return;
          }
          this.getFuncs();
          this.$Message.info('删除成功');
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>