<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="form.name" clearable :placeholder="showLang('com.device.pole.name')" style="width: 400px; margin-right: 10px;"></Input>
      {{showLang('com.group')}}：
      <Select :placeholder="showLang('save.select')" v-model="form.areaId" style="width: 123px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in areas">
          <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
        </template>
      </Select>
      <Button v-if="funCodes('lv')" size="default" type="primary" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
      {{showLang('com.data.sum.record',list.length)}} 
    </div>
    <div class="query-data-area">
      <!-- <Button v-if="funCodes('la')" size="default" type="primary" @click="importLampNew" style="margin: 0 5px;">{{showLang('import.batch')}}</Button> -->
      <template v-for="(btn, idx) in btns">
        <Button v-if="funCodes('le')" size="default" :key="idx" type="primary" @click="docmd(btn)" style="margin: 2px 5px;">{{showLang(btn.lang)}}</Button>
      </template>
      <Button v-if="funCodes('la')" size="default" type="primary" @click="repairStationEnergy" style="margin:2px 5px;">{{showLang('com.data.re.energy.count')}}</Button>
      <Button v-if="funCodes('ld')" size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}">
          <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')"></vxe-column>
          <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
          <vxe-column field="name" :title="showLang('com.tab.title')" width="150" header-align="center">
            <template #default="{ row }">
              {{row.name}}
            </template>
          </vxe-column>
          <vxe-column field="stationName" :title="showLang('com.site')"  width="250" header-align="center">
            <template #default="{ row }">
              {{row.stationName}}
            </template>
          </vxe-column>
          <vxe-column field="poleName" :title="showLang('com.export.cn.pole.relate')"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.poleName}}
            </template>
          </vxe-column>
          <vxe-column field="areaName" :title="showLang('com.export.cn.group.relate')"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.areaName}}
            </template>
          </vxe-column>
          <!-- <vxe-column field="connectType" :title="showLang('555555')接入方式"  width="150" header-align="center">
            <template #default="{ row }">
              {{getConnectTypeName(row.connectType)}}
            </template>
          </vxe-column> -->
          <vxe-column field="code" :title="showLang('com.export.cn.id')"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.code}}
            </template>
          </vxe-column>
          <vxe-column field="deviceTypeName" :title="showLang('com.export.cn.type')"  width="250" header-align="center">
            <template #default="{ row }">
              {{row.deviceTypeName}}
            </template>
          </vxe-column>
          <vxe-column field="timeName" :title="showLang('com.ins.timeTable')"  width="250" header-align="center">
            <template #default="{ row }">
              {{row.timeName}}
            </template>
          </vxe-column>
          <vxe-colgroup :title="showLang('com.lighting.V')" header-align="center">
            <vxe-column field="ur" :title="showLang('com.lighting.rated.v.val')" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.ur}} V
              </template>
            </vxe-column>
            <vxe-column field="uh" :title="showLang('com.data.upper.val')" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.uh}} %
              </template>
            </vxe-column>
            <vxe-column field="ul" :title="showLang('com.data.lower.val')" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.ul}} %
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ope.nav.channel.1')" header-align="center">
            <vxe-column field="en1" :title="showLang('com.state.enable')" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en1 ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="nm1" :title="showLang('com.tab.title')" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.nm1}}
              </template>
            </vxe-column>
            <vxe-column field="pr1" :title="showLang('com.lighting.pr1')" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.pr1}} W
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ope.nav.channel.2')" header-align="center">
            <vxe-column field="en2" :title="showLang('com.state.enable')" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.en2 ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="nm2" :title="showLang('com.tab.title')" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.nm2}}
              </template>
            </vxe-column>
            <vxe-column field="pr2" :title="showLang('com.lighting.pr1')" header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.pr2}} W
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ope.nav.channel.3')" header-align="center">
            <vxe-column field="en3" :title="showLang('com.state.enable')" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en3 ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="nm3" :title="showLang('com.tab.title')" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.nm3}}
              </template>
            </vxe-column>
            <vxe-column field="pr3" :title="showLang('com.lighting.pr1')" header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.pr3}} W
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ope.nav.channel.4')" header-align="center">
            <vxe-column field="en4" :title="showLang('com.state.enable')" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en4 ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="nm4" :title="showLang('com.tab.title')" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.nm4}}
              </template>
            </vxe-column>
            <vxe-column field="pr4" :title="showLang('com.lighting.pr1')" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.pr4}} W
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ins.updateLeakageRate')" header-align="center">
            <vxe-column field="ll" :title="showLang('com.stat.level1')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.ll}} mA
              </template>
            </vxe-column>
            <vxe-column field="lh" :title="showLang('com.stat.level2')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.lh}} mA
              </template>
            </vxe-column>
            <vxe-column field="lx" :title="showLang('com.stat.level3')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.lx || '-'}} mA
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup :title="showLang('com.ins.updateWaterRate')" header-align="center">
            <vxe-column field="l1" :title="showLang('com.stat.level1')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l1}}
              </template>
            </vxe-column>
            <vxe-column field="l2" :title="showLang('com.stat.level2')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l2}}
              </template>
            </vxe-column>
            <vxe-column field="l3" :title="showLang('com.stat.level3')"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l3}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-column width="160" fixed="right">
            <template slot="header">
              <AuthButton opCode='la' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</AuthButton>
              <AuthButton opCode='lv' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
            </template>
            <template #default="{ row }">
              <AuthButton opCode='le' style="margin-right: 5px" @click="handleEdit(row)">{{showLang('com.op.edit')}}</AuthButton>
              <AuthButton opCode='ld' type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</AuthButton>
            </template>
          </vxe-column>

      </vxe-table>
      <ModalLampEdit v-model="showEditModal" :item="editItem" :areas="areas" :stations="selectedStations" @saved="itemSaved" />
      <ModalLampImportNew v-model="showImportModalNew" :item="editItem" :areas="areas" :stations="selectedStations" @saved="itemSaved" />
      <ModalEditArgs v-model="showArgModal" :item="editItem" :areas="areas" @saved="argsSaved" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLampEdit from './ModalLampEdit'
import ModalLampImportNew from './ModalLampImportNew'
import ModalEditArgs from './ModalEditArgs'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLampEdit,
    ModalLampImportNew,
    ModalEditArgs,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      showImportModalNew: false,
      showImportModalOld: false,
      exportLoading: false,
      dataRefresh: 0,
      form: {
        name: '',
        areaId: 0,
      },
      areas: [],
      btns: [
        { code: 'updateControlChannel', name: '设定控制通道',lang:'com.ins.set.updateControlChannel' },
        { code: 'updateLightGroup', name: '设定分组',lang:'com.ins.set.updateLightGroup' },
        { code: 'updateLightType', name: '设定类型',lang:'com.ins.set.updateLightType' },
        { code: 'updateLightTimeTable', name: '设定时间表',lang:'com.ins.set.updateLightTimeTable' },
        { code: 'updateLeakageRate', name: '设定漏电阈值',lang:'com.ins.set.updateLeakageRate' },
        { code: 'updateWaterRate', name: '设定水浸阈值',lang:'com.ins.set.updateWaterRate' },
        { code: 'updateTempRate', name: '设定温度阈值',lang:'com.ins.set.updateTempRate' },
        { code: 'updateVoltageRate', name: '设定电压阈值',lang:'com.ins.set.updateVoltageRate' },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'connectTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.queryAreas();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    exportData: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.export.err.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.exportLoading = true;
      this.$axios.request({
        url: `//${this.domains.trans}/station/config/ExportSelectedLight`,
        method: 'post',
        data: { list },
        responseType: 'blob'
      }).then(res => {
        this.exportLoading = false;
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('com.site.meter.reading')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    docmd: function(params){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      this.editItem = params;
      this.showArgModal = true;
    },
    argsSaved: function(params){
      console.log('batch edit args saved', params);
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$axios.post(`//${this.domains.trans}/station/config/BatchEditLight`, {
        list, code: params.code, args: params.args 
      }).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.getList();
        }
      });
    },
    queryAreas: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'areas', res.data);
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    getConnectTypeName: function (type) {
      let ts = this.connectTypes.filter(p => p.code == type);
      if (ts.length == 0) return '';
      return ts[0].name;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: list }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name',params.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [params.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    repairStationEnergy: function(){
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.site.energy'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/third/test/RepairStationBeforeEnergy`, { groupId, stationId }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.data.count.record',res.data));
            }
          });
        }
      });
    },
    getList: function () {
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryPoleLight`, { groupId, poleId: stationId, name: this.form.name, group: this.form.areaId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          this.dataRefresh++;
        }
      });
    },
    importLampNew: function () {
      this.item = {};
      this.showImportModalNew = true;
    },
    importLampOld: function () {
      this.$Modal.info({
        title: this.showLang('com.tips.function'),
        content: this.showLang('com.tips.function.con'),
      })
      // this.item = {};
      // this.showImportModalOld = true;
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin: 5px 0;
  padding: 5px 0;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>